import AppConfig from "./config/AppConfig";
import moment from "moment";

const PARTITIONS = {
    FORECAST:0,
    KRIGING:1,
    HRRR:2,
    NAQFC:3,
    AIRNOW:4,
    CLARITY:5,
    PURPLEAIR:6,
    MONITOR_FORECAST:7
}

const DOMAINS = {
    CONUS: 0,
    ALASKA: 1,
    HAWAII: 2
}

const POLLUTANTS ={
    PM2P5: 0,
    OZONE: 1,
}

let vsprintf = require('sprintf-js').vsprintf

const buildS3Path = function (partition,dateTime, currentInit) {

    switch(partition){
        case PARTITIONS.FORECAST:
            return _buildForecastPath(dateTime);
        case PARTITIONS.KRIGING:
            return _buildKrigingPath(dateTime);
        case PARTITIONS.HRRR:
            return _buildHRRRPath(dateTime, "conus");
        case PARTITIONS.NAQFC:
            return _buildNaqfcPath(dateTime, "pm2p5");
        case PARTITIONS.AIRNOW:
            return _buildAirNowPath(dateTime);
        case PARTITIONS.CLARITY:
            return _buildClarityPath(dateTime);
        case PARTITIONS.PURPLEAIR:
            return _buildPurpleAirPath(dateTime);
        case PARTITIONS.MONITOR_FORECAST:
            return _buildMonitorForecastPath(dateTime, currentInit);
    }
}

const _buildKrigingPath = function (dateTime) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name kriging_model_conus_pm2p5_1999102101.tiff
    let fileName = vsprintf(AppConfig.Kriging_BASE_NAME, YYYYMMDDHH);

    //sample path mapping/curated/kriging/model/1999/199910/19991021/kriging_model_conus_pm2p5_1999102101.tiff
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.Kriging_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName]);

    return bucketPath;
}

const _buildForecastPath = function (dateTime) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name forecast_model_conus_pm2p5_1999102101.tiff
    let fileName = vsprintf(AppConfig.Forecast_BASE_NAME, YYYYMMDDHH);

    //sample path mapping/curated/forecast/model/1999/199910/19991021/Forecast_model_conus_pm2p5_1999102101.tiff
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.Forecast_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName])
    return bucketPath;
}

const _buildAirNowPath = function (dateTime) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name airnow_obs_conus_pm2p5_19991021.geojson
    let fileName  = vsprintf(AppConfig.AirNow_BASE_NAME, YYYYMMDDHH);

    //sample path mapping/normalize/airnow/obs/1999/199910/19991021/airnow_obs_conus_pm2p5_19991021.geojson
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.AirNow_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName]);

    return bucketPath;
}

const _buildClarityPath = function (dateTime) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name clarity_obs_conus_pm2p5_19991021.geojson
    let fileName = vsprintf(AppConfig.Clarity_BASE_NAME, YYYYMMDDHH);

    //sample path mapping/normalize/clarity/obs/1999/199910/19991021/clarity_obs_conus_pm2p5_19991021.geojson
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.Clarity_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName]);

    return bucketPath;
}

const _buildPurpleAirPath = function (dateTime) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name purpleair_obs1hr_conus_pm2p5_19991021.geojson
    let fileName = vsprintf(AppConfig.PurpleAir_BASE_NAME, YYYYMMDDHH);

    //sample path mapping/normalize/purpleair/obs1hr/1999/199910/19991021/purpleair_obs1hr_conus_pm2p5_19991021.geojson
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.PurpleAir_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName]);

    return bucketPath;
}

const _buildHRRRPath = function (dateTime,domain) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name noaa_hrrr_conus_pm2p5_1999102101.tiff
    let fileName = vsprintf(AppConfig.HRRR_BASE_NAME, [domain, YYYYMMDDHH]);

    //sample path mapping/normalize/noaa/hrrr/1999/199910/19991021/noaa_hrrr_conus_pm2p5_1999102101.tiff
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.HRRR_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName]);

    return bucketPath;
}

const _buildNaqfcPath = function (dateTime,pollutant) {
    dateTime = moment(dateTime);
    let YYYYMMDDHH = dateTime.format("YYYYMMDDHH");
    let YYYYMMDD = dateTime.format("YYYYMMDD");
    let YYYYMM = dateTime.format("YYYYMM");
    let YYYY = dateTime.format("YYYY");

    //sample name noaa_naqfc-bc_conus_pm2p5_19991021.tiff
    let fileName = vsprintf(AppConfig.NAQFC_BASE_NAME, [pollutant, YYYYMMDDHH]);


    //sample path mapping/normalize/noaa/naqfc-bc/1999/199910/19991021/noaa_naqfc-bc_conus_pm2p5_19991021.tiff
    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.NAQFC_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,fileName]);

    return bucketPath;
}

const _buildMonitorForecastPath = function  (dateTime, currentInit) {
    dateTime = moment(dateTime);
    let dt_now = moment.utc().add(-1,'hours').set({minute:0,second:0,millisecond:0})
    let diff_hours = dateTime.diff(currentInit? dt_now : dt_now.set({hour:0}), 'hours')
    diff_hours = Math.max(0,diff_hours)

    let fmt_dt = dateTime;
    if(dateTime.isAfter(dt_now) ){
        fmt_dt = dt_now
    }

    let YYYYMMDDHH = currentInit ? fmt_dt.format("YYYYMMDDHH") : fmt_dt.format("YYYYMMDD00");
    let YYYYMMDD = fmt_dt.format("YYYYMMDD");
    let YYYYMM = fmt_dt.format("YYYYMM");
    let YYYY = fmt_dt.format("YYYY");

    let fileName = vsprintf(AppConfig.MonitorForecast_BASE_NAME, [YYYYMMDDHH,  diff_hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})])

    let bucketPath = vsprintf(AppConfig.SHARED_S3_PREFIX + AppConfig.MonitorForecast_BASE_KEY, [YYYY,YYYYMM,YYYYMMDD,YYYYMMDDHH, fileName]);

    return bucketPath;
}

export  {PARTITIONS, DOMAINS, POLLUTANTS, buildS3Path}

