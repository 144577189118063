// OpenLayers Style Object - https://openlayers.org/en/v7.2.2/apidoc/module-ol_layer_WebGLTile.html#~Style
// OpenLayers ExpressionValue - https://openlayers.org/en/v7.2.2/apidoc/module-ol_style_expressions.html#~ExpressionValue

// See "nodata" in GeoTiff SourceInfo for why this is - https://openlayers.org/en/v7.2.2/apidoc/module-ol_source_GeoTIFF.html#~SourceInfo
const DATA_BAND = 1
const ALPHA_BAND = 2  // values are 0 for "nodata"

export default {
    US_EPA_AQI_DISTINCT: {
        color: [
            'case',
            ['>=', ['band', DATA_BAND], 250.5], // Hazardous
            [2, 0, 35, 1.0],                    //   Magenta (r, g, b, a)
            ['>=', ['band', DATA_BAND], 150.5], // Very Unhealthy
            [143, 63, 151, 1.0],                //   Purple
            ['>=', ['band', DATA_BAND], 55.5],  // Unhealthy
            [255, 0, 0, 1.0],                   //   Red
            ['>=', ['band', DATA_BAND], 35.5],  // USG
            [255, 126, 0, 1.0],                 //   Orange
            ['>=', ['band', DATA_BAND], 12.1],  // Moderate
            [255, 255, 0, 1.0],                 //   Yellow
            ['>=', ['band', DATA_BAND], -10.0], // Good
            [0, 228, 0, 1.0],                   //   Green
            [0, 0, 0, 0.0]                      // No Data, Black
        ]
    },
    US_EPA_AQI_LINEAR: {  // aka: "gradient"
        color: [
            'case',
            ['!=', ['band', ALPHA_BAND], 0], // Only style if value is not "nodata"
            [
                'interpolate', // Transform Operator
                ["linear"],    // "interpolate" method
                ['band', DATA_BAND],   // Input value (this uses pixel values)
                -10.0,               // Good (treat negative values as if they were 0)
                [0, 228, 0, 1.0],    //   Green
                0,                   // Good (start gradient here)
                [0, 228, 0, 1.0],    //   Green
                12.1,                // Moderate
                [255, 255, 0, 1.0],  //   Yellow
                35.5,                // USG
                [255, 126, 0, 1.0],  //   Orange
                55.5,                // Unhealthy
                [255, 0, 0, 1.0],    //   Red
                150.5,               // Very Unhealthy
                [143, 63, 151, 1.0], //   Purple
                250.5,               // Hazardous
                [2, 0, 35, 1.0],     //   Magenta
                9999,                // Beyond the AQI
                [2, 0, 35, 1.0]      //   Magenta
            ],
            [0, 0, 0, 0.0]         // No Data, Not Visible
        ]
    },
    EXACTAQ_PM25_LINEAR: {  // aka: "gradient"
        color: [
            'case',
            ['!=', ['band', ALPHA_BAND], 0], // Only style if value is not "nodata"
            [
                'interpolate', // Transform Operator
                ["linear"],    // "interpolate" method
                ['band', DATA_BAND],   // Input value (this uses pixel values)
                -10.0, [0,120,191, 1.0],
                0.0, [0,120,191, 1.0],
                2.0, [44,164,221, 1.0],
                4.0, [73,201,245, 1.0],
                6.0, [69,184,85, 1.0],
                8.0, [46,136,65, 1.0],
                9.1, [225,205,23, 1.0],
                20.0, [225,201,23, 1.0],
                27.0, [225,198,23, 1.0],
                35.5, [243,134,9, 1.0],
                39.0, [243,126,9, 1.0],
                45.0, [246,119,8, 1.0],
                51.0, [255,111,0, 1.0],
                55.5, [255,81,0, 1.0],
                72.0, [255,51,0, 1.0],
                90.0, [255,34,0, 1.0],
                107.0, [255,21,0, 1.0],
                125.5, [123,13,219, 1.0],
                150, [114,14,196, 1.0],
                175.0, [98,13,168, 1.0],
                200.0, [87,14,152, 1.0],
                225.5, [145,44,23, 1.0],
                9999, [145,44,23, 1.0]
            ],
            [0, 0, 0, 0.0] // No Data
        ]
    }
};
