import { GeoTIFF as GeoTIFFSource } from 'ol/source';

// ol/source/GeoTIFF - https://openlayers.org/en/latest/apidoc/module-ol_source_GeoTIFF-GeoTIFFSource.html

function geotiff({ sources }) {
    // Create and return OpenLayers GeoTIFF Source Object
    return new GeoTIFFSource({
        sources: sources,
        normalize: false,
        interpolate: false,  // (default: true) If true, linear interpolation is used and raster will look "simplified"
        sourceOptions: {
          allowFullFile: true
        }
    });
}

export default geotiff;
