import React, { useContext, useEffect, useState } from "react";
import { FullScreen } from "ol/control";
import MapContext from "../Map/MapContext";

// ol/control/FullScreen - https://openlayers.org/en/latest/apidoc/module-ol_control_FullScreen-FullScreen.html

const FullScreenControl = () => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers FullScreen Control Object
        let fullScreenControl = new FullScreen({});

        // Add FullScreen Control to map
        map.controls.push(fullScreenControl);

        return () => map.controls.remove(fullScreenControl);
    }, [map]);

    return null;
};

export default FullScreenControl;