import React from "react";

// Style
import "./TimeSelector.css";

import * as i18nDict from "../i18n/i18n";
import {getLocale} from "./i18n";
import ReactSlider from "react-slider";
import moment from "moment-timezone";


import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {RxDoubleArrowRight, RxDoubleArrowLeft} from "react-icons/rx";
import{BsCalendar4Week} from "react-icons/bs"



class TimeSelector extends React.Component {

    constructor(props) {
        super(props);

        this.timezone = moment.tz.guess();
        this.state = {
            i18n: i18nDict[getLocale()],
            locale: getLocale(),
            sliderValue: 0 + moment.tz(this.timezone).startOf('hour').add(-1,'hours').hour(),
            selectedTime: moment.tz(this.timezone).startOf('hour').add(-1,'hours'),
            timelineCenter: moment.tz(this.timezone).startOf('day'),
            monitorForecastSelected: this.props.monitorForecastSelected,
            currentForecastSelected: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props != prevProps){
            this.setState(
                {
                    monitorForecastSelected : this.props.monitorForecastSelected,
                });
        }
        if (this.state.currentForecastSelected != prevState.currentForecastSelected){
            this.props.parentInitCallback(this.state.currentForecastSelected)
        }
    }


    _renderTimeLine(){

        return(
            <div className={"time-line"}>

                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-left-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>
                <div className={"time-line-right-hash"}>
                    <div className={"time-line-center-hash"}/>
                    <div className={"time-line-center-hash"}/>
                </div>

            </div>

        )
    }
    _renderTimeLineLabels(){
       return(
           <div className={"time-line-label-container"}>
               <div className={"time-line-label"}>
                   12AM
                   <br/>
                   {this._getSelectedDayDelta(-2)}
               </div>
               <div className={"time-line-label"}>

               </div>
               <div className={"time-line-label"}>
                   12AM
                   <br/>
                   {this._getSelectedDayDelta(-1)}
               </div>
               <div className={"time-line-label"}>

               </div>
               <div className={"time-line-label"}>
                   12AM
                   <br/>
                   {this._getSelectedDayDelta(0)}
               </div>
               <div className={"time-line-label"}>

               </div>
               <div className={"time-line-label"}>
                   12AM
                   <br/>
                   {this._getSelectedDayDelta(1)}
               </div>
               <div className={"time-line-label"}>

               </div>
               <div className={"time-line-label"}>
                   12AM
                   <br/>
                   {this._getSelectedDayDelta(2)}
               </div>
           </div>

       )

    }

    _renderTimeLineSlider(){
        return (
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                min={-48}
                max={48}
                renderThumb={(props, state) => <div {...props}></div>}
                value={this.state.sliderValue}
                onAfterChange = { (value, thumbIndex) => this._updateSelectedTime(value)}
                onChange = { (value, thumbIndex) => this._updateDisplayTime(value)}
            />
        )
    }
    _renderSelectedTime(){

        return(
            <div className={"timeline-container-title"}>
                {this.state.selectedTime.format('h:00 A MMM DD z')}
            </div>
        )
    }

    _getSelectedDayDelta(diffDays){
        return moment(this.state.timelineCenter).tz(this.timezone).startOf('day').add(diffDays,'days').format('MMM DD');
    }

   _updateSelectedTime(value){
       let newSelectedTime = this.state.selectedTime;
       this.props.parentTimeCallback(moment.utc(newSelectedTime));
   }


   _updateDisplayTime(value){
        let newSelectedTime = this.state.selectedTime;
        this.setState({selectedTime:newSelectedTime.add(value - this.state.sliderValue, 'hours')});
        this.setState({sliderValue:value});
    }

    _handleDatePickerCallback(time){
        this.setState({selectedTime: moment(time).tz(this.timezone), sliderValue: 0, timelineCenter: time.startOf("day")});
        //utc time for map page to align with s3 file paths
        this.props.parentTimeCallback(moment.utc(time.startOf('day')));
    }

    _renderButtonGroup(){
        return(
            <div className={"button-group"}>

                <div>
                    <DateRangePicker
                        initialSettings={{
                            startDate: this.state.selectedTime,
                            //timePicker: true,
                            singleDatePicker: true,
                            drops:"up",

                        }}
                        onCallback={this._handleDatePickerCallback.bind(this)}
                    >
                        <button className={"calendar-button"}>
                            <BsCalendar4Week/>
                        </button>
                    </DateRangePicker>
                </div>

                <div>
                    <a className="time-increment-button"  onClick={() => {
                        if(this.state.sliderValue > -48){
                            this._updateDisplayTime(this.state.sliderValue - 1)
                            this._updateSelectedTime(this.state.sliderValue)
                        }}
                    }>
                        <RxDoubleArrowLeft/>
                    </a>
                </div>

                <div>
                    <a className="time-increment-button"  onClick={() =>  {
                        if( this.state.sliderValue < 48){
                            this._updateDisplayTime(this.state.sliderValue + 1)
                            this._updateSelectedTime(this.state.sliderValue)
                        }}
                    }>
                        <RxDoubleArrowRight/>
                    </a>
                </div>
            </div>
        )
    }

    _renderForecastToggle(){
        return(
            <div className={"forecast-toggle-group"}>
                <label className={"forecast-toggle-title"}>
                    {this.state.i18n.FORECAST_TOGGLE_TITLE}
                </label>

                <div className={"forecast-toggle-control-group"}>
                    <label className={"forecast-midnight-label"}>
                        {this.state.i18n.FORECAST_TOGGLE_MIDNIGHT}
                    </label>

                    <label className="switch">
                        <input
                            type="checkbox"
                            onClick={ (event) => {
                                this.setState({currentForecastSelected:event.target.checked});
                            }}
                            defaultChecked={this.state.currentForecastSelected}
                            value={this.state.currentForecastSelected}
                        />
                        <span className="slider round"></span>
                    </label>

                    <label className={"forecast-current-label"}>
                        {this.state.i18n.FORECAST_TOGGLE_CURRENT}
                    </label>
                </div>

            </div>
        )
    }

    render(){
        return(
            <div className={"time-selector-card"} style={{width: this.state.monitorForecastSelected ? 700 : 500}}>

                <div className={"control-group"}>
                    {this._renderButtonGroup()}
                </div>

                <div className={"time-line-group"}>
                    {this._renderSelectedTime()}
                    <div className={"time-line-container"}>
                        {this._renderTimeLine()}
                        {this._renderTimeLineSlider()}
                        {this._renderTimeLineLabels()}
                    </div>
                </div>

                {this.state.monitorForecastSelected ? this._renderForecastToggle(): <div/>}

            </div>
        )
    }


}
export default TimeSelector