import "./SidePanel.css";
import React from "react";

import {ApiService} from "../ApiService"
import {PARTITIONS, DOMAINS, POLLUTANTS, buildS3Path} from "../S3PathBuilder"
import parse from 'html-react-parser'

import * as i18nDict  from "../i18n/i18n";
import {getLocale} from "./i18n";

import {BsFillCircleFill,BsFillSquareFill,BsFillTriangleFill} from "react-icons/bs";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md"
import {BiLinkExternal} from "react-icons/bi"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";


class SidePanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            i18n: i18nDict[getLocale()],
            locale: getLocale(),
            envIsProd: process.env.REACT_APP_ENVIRONMENT == "prod",
            isExpanded: true,
            isSyncEnabled: true,
            isAllParametersExpanded: false,
            rawLocationText: "",
            geocodeResponse: "",

            selectedSource: PARTITIONS.KRIGING,
            selectedPollutant: "",
            selectedScale:"",

            airNowSelected:false,
            purpleAirSelected:false,
            claritySelected:false,
            monitorForecastSelected:false,

            webappSettings: null,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.webappSettings != this.props.webappSettings){
            this.setState({webappSettings:this.props.webappSettings})
        }
        if (prevProps.defaultSelected != this.props.defaultSelected){
            this.setState({
                airNowSelected:this.props.defaultSelected.includes(PARTITIONS['AIRNOW']),
                purpleAirSelected:this.props.defaultSelected.includes(PARTITIONS['PURPLEAIR']),
                claritySelected:this.props.defaultSelected.includes(PARTITIONS['CLARITY']),
                monitorForecastSelected:this.props.defaultSelected.includes(PARTITIONS['MONITOR_FORECAST'])
            })
        }

    }

    _onToggleExpandedState () {
        let newExpandedState = !this.state.isExpanded;
        this.setState({
            isExpanded: newExpandedState
        });
    }

    _setSource (event){

        if( event.target.value !== 'on'){
            this.setState({selectedSource:event.target.value});
            this.onSelectorTrigger(
                event.target.value,
                this.state.airNowSelected,
                this.state.purpleAirSelected,
                this.state.claritySelected,
                this.state.monitorForecastSelected
            )
        }
    }

    _setPollutant (event){
        this.setState({selectedPollutant:[event.target.value]})
    }

    _renderColorRampScale () {

        return(
            <div className={"color-ramp-container"}>
                <div className={"tick-marks"}>
                    <div className={"tick-mark"}/>
                    <div className={"tick-mark"}/>
                    <div className={"tick-mark"}/>
                    <div className={"tick-mark"}/>
                    <div className={"tick-mark"}/>
                    <div className={"tick-mark"}/>
                </div>
                <div className={"concentration-row"}>
                    <div className={"concentration-start-text"}>
                        {this.state.i18n.CONCENTRATION_MIN_VALUE}
                    </div>

                    <div className={"concentration-gradient"}>

                    </div>

                    <div className={"concentration-end-text"}>
                        {this.state.i18n.CONCENTRATION_MAX_VALUE}
                    </div>
                </div>
            </div>

        );
    }

    _renderConcentrationSymbol(){
        let concentationStyle = {color:this.props.pointInfo.concentrationColor,stroke: "black", strokeWidth: "1", margin:"8px 0px 0 8px"};

        if(this.props.pointInfo.vectorClickType == "airnow"){
            return <BsFillCircleFill size={30} viewBox={"-.5 -.5 17 17"} style={concentationStyle}/>;
        }
        if(this.props.pointInfo.vectorClickType == "clarity"){
            return <BsFillTriangleFill size={30} viewBox={"-.5 -.5 17 17"} style={concentationStyle}/>;
        }
        if(this.props.pointInfo.vectorClickType == "purpleair"){
            return  <BsFillSquareFill size={30} viewBox={"-.5 -.5 17 17"} style={concentationStyle}/>;
        }

        //default for non vector clicks
        return <BsFillCircleFill size={30} viewBox={"-.5 -.5 17 17"} style={concentationStyle}/>;
    }

    _renderSelectedPointInfo(){
        return(
            <div className={"point-info-container"}>
                <div className={"point-concentration-row"}>

                    <div className={"concentration-text"}>

                        <div className={"concentration-text-header"}>
                            {this.props.pointInfo.concentrationValue}
                        </div>

                        <div className={"concentration-text-sub"}>
                            {this.state.i18n.UNITS_UGM}<sup>3</sup>
                        </div>

                    </div>

                    {this._renderConcentrationSymbol()}
                </div>

                <div className={"location-row"}>
                    <div className={"place-text"}>
                        {this.props.pointInfo.place}
                    </div>

                    <div className={"coords-text"}>
                        {this.props.pointInfo.coordinates}
                    </div>

                </div>

            </div>
        )
    }

    _renderSelectors () {

        return(
            <div className={"selector-container"}>

                <div>
                    <a className="side-panel-button"  onClick={this._onToggleExpandedState.bind(this)}>
                        <MdKeyboardArrowLeft size={42}/>
                    </a>
                </div>

                {this._renderLocationSearchEntry()}

                { this.state.geocodeResponse != "" ? this._renderLocationSearchPanel(): ""}

                {this._renderColorRampScale()}

                {this.props.pointIsActive ? this._renderSelectedPointInfo(): <div>{this.props.pointIsActive}</div>}

                <div className={"selector-item"}>

                    <div className="selector-label">
                        {this.state.i18n.POLLUTANTS_LABEL}
                    </div>

                    <select className="form-select" aria-label="Default select example" onChange={this._setPollutant.bind(this)}>
                        <option value={POLLUTANTS.PM2P5}>
                            {this.state.i18n.PM25_LABEL}
                        </option>
                    </select>

                </div>



                <div className={"selector-item"}>
                    <div className="selector-label">
                        {this.state.i18n.SCALE_LABEL}
                    </div>

                    <select className="form-select" aria-label="Default select example">
                        <option> {this.state.i18n.CONCENTRATIONS_LABEL}</option>
                    </select>

                </div>

                <div className={"selector-item"} onChange={this._setSource.bind(this)}>

                    {
                        this.state.webappSettings && (this.state.webappSettings.layers.visibility['KRIGING'] ||
                            this.state.webappSettings.layers.visibility['FORECAST'] ||
                            this.state.webappSettings.layers.visibility['MONITOR_FORECAST']) ?

                            <div className="selector-label">
                                {this.state.i18n.EXACTAQ_SOURCES_LABEL}
                            </div>
                            :
                            <div/>
                    }


                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['KRIGING'] ?

                        <div className="form-check">
                            <input className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={this.state.selectedSource == PARTITIONS.KRIGING}
                                value={PARTITIONS.KRIGING}
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                <li className="layer-tooltip" >

                                    <p className={"measurement-text"}>
                                        {this.state.i18n.KRIGING_LABEL}
                                    </p>

                                    <span className="layer-tooltiptext">
                                        {this.state.i18n.KRIGING_TOOLTIP}
                                    </span>
                                </li>
                            </label>

                        </div>
                        :
                        <div/>
                    }

                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['MONITOR_FORECAST'] ?

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.state.monitorForecastSelected}
                                    disabled={this.state.airNowSelected || this.state.purpleAirSelected}
                                    onChange={() =>{
                                        this.onSelectorTrigger(
                                            this.state.selectedSource,
                                            false, // AirNow
                                            false, // Purple Air
                                            this.state.claritySelected,
                                            !this.state.monitorForecastSelected
                                        );
                                        this.setState({
                                            monitorForecastSelected: !this.state.monitorForecastSelected,
                                            purpleAirSelected: false,
                                            airNowSelected: false,
                                        });
                                    }
                                 }
                                    id="flexCheckChecked"
                                />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                <div className={"label-flex"}>
                                    <li className="layer-tooltip" >

                                        <p className={"measurement-text"}>
                                            {this.state.i18n.MONITOR_FORECAST_LABEL}

                                            <span className="layer-tooltiptext">
                                                {this.state.i18n.MONITOR_FORECAST_TOOLTIP}
                                            </span>
                                        </p>
                                    </li>

                                </div>
                                </label>
                                </div>

                            :
                            <div/>
                    }

                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['FORECAST'] ?

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    checked={this.state.selectedSource == PARTITIONS.FORECAST}
                                    value={PARTITIONS.FORECAST}
                                />

                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    <li className="layer-tooltip">
                                        <p className={"measurement-text"}>
                                            {this.state.i18n.FORECAST_LABEL}

                                            <span className="layer-tooltiptext">
                                            {this.state.i18n.FORECAST_TOOLTIP}
                                        </span>
                                        </p>
                                    </li>

                                </label>
                            </div>
                            :
                            <div/>
                    }



                </div>

                <div className={"selector-item"}>


                    {
                        this.state.webappSettings && (this.state.webappSettings.layers.visibility['AIRNOW'] ||
                            this.state.webappSettings.layers.visibility['PURPLEAIR'] ||
                            this.state.webappSettings.layers.visibility['CLARITY']) ?

                            <div className="selector-label">
                                {this.state.i18n.MEASUREMENTS_LABEL}
                            </div>
                            :
                            <div/>
                    }



                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['AIRNOW'] ?

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.state.airNowSelected}
                                    disabled={this.state.monitorForecastSelected}
                                    onChange={() =>
                                    {
                                        this.onSelectorTrigger(
                                            this.state.selectedSource,
                                            !this.state.airNowSelected,
                                            this.state.purpleAirSelected,
                                            this.state.claritySelected,
                                            false // MonitorForecast
                                        );
                                        this.setState({
                                            airNowSelected: !this.state.airNowSelected,
                                            monitorForecastSelected: false
                                        });
                                    }
                                    }
                                    id="flexCheckDefault"
                                />

                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    <li className="layer-tooltip" >
                                        <div className={"label-flex"}>

                                            <p className={"measurement-text"}>
                                                {this.state.i18n.AIRNOW_LABEL}
                                            </p>


                                            <span className="layer-tooltiptext">
                                                {this.state.i18n.AIRNOW_TOOLTIP}
                                            </span>

                                            <label className={"symbol-container"}>
                                                <BsFillCircleFill/>
                                            </label>

                                        </div>
                                    </li>
                                </label>
                            </div>
                            :
                            <div/>
                    }

                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['PURPLEAIR'] ?

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.state.purpleAirSelected}
                                    disabled={this.state.monitorForecastSelected}
                                    onChange={() =>
                                    {

                                        this.onSelectorTrigger(
                                            this.state.selectedSource,
                                            this.state.airNowSelected,
                                            !this.state.purpleAirSelected,
                                            this.state.claritySelected,
                                            false // MonitorForecast
                                        )

                                        this.setState({
                                            purpleAirSelected: !this.state.purpleAirSelected,
                                            monitorForecastSelected: false
                                        });
                                    }
                                    }
                                    id="flexCheckChecked"
                                />

                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        <li className="layer-tooltip">

                                                <div className={"label-flex"}>
                                                    <p className={"measurement-text"}>
                                                        {this.state.i18n.PURPLEAIR_LABEL}
                                                    </p>

                                                    <span className="layer-tooltiptext">
                                                        {this.state.i18n.PURPLEAIR_TOOLTIP}
                                                    </span>

                                                    <label className={"symbol-container"}>
                                                        <BsFillSquareFill/>
                                                    </label>
                                                </div>
                                        </li>
                                    </label>

                            </div>
                            :
                            <div/>
                    }

                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['CLARITY'] ?

                            <div className="form-check" style={{display:this.state.envIsProd ? "none":""}}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.state.claritySelected}
                                    onChange={() =>
                                    {
                                        this.onSelectorTrigger(
                                            this.state.selectedSource,
                                            this.state.airNowSelected,
                                            this.state.purpleAirSelected,
                                            !this.state.claritySelected,
                                            this.state.monitorForecastSelected
                                        );
                                        this.setState({claritySelected: !this.state.claritySelected});
                                    }
                                    }
                                    id="flexCheckChecked"
                                />
                                <label className="form-check-label" htmlFor="flexCheckChecked">


                                        <li className="layer-tooltip">
                                            <div className={"label-flex"}>
                                                <p className={"measurement-text"}>
                                                    {this.state.i18n.CLARITY_LABEL}
                                                </p>

                                                <label className={"symbol-container"} style={{color:"gray"}}>
                                                    <BsFillTriangleFill/>
                                                </label>

                                                <span className="layer-tooltiptext">
                                                        {this.state.i18n.CLARITY_TOOLTIP}
                                                </span>
                                            </div>
                                        </li>

                                </label>
                            </div>
                            :
                            <div/>
                    }

                </div>



                <div className={"selector-item"} onChange={this._setSource.bind(this)}>

                    {
                        this.state.webappSettings && (this.state.webappSettings.layers.visibility['NAQFC'] ||
                        this.state.webappSettings.layers.visibility['NAQFC']) ?

                        <div className="selector-label">
                            {this.state.i18n.MODEL_SOURCES_LABEL}
                        </div>
                        :
                          <div/>
                    }


                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['NAQFC'] ?

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault3"
                                    checked={this.state.selectedSource == PARTITIONS.NAQFC}
                                    value={PARTITIONS.NAQFC}
                                />

                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    <li className="layer-tooltip">
                                        <p className={"measurement-text"}>
                                            {this.state.i18n.NAQFC_LABEL}
                                        </p>
                                        <span className="layer-tooltiptext">
                                            {this.state.i18n.NAQFC_TOOLTIP}
                                        </span>
                                    </li>
                                </label>

                            </div>
                            :
                            <div/>
                    }

                    {
                        this.state.webappSettings && this.state.webappSettings.layers.visibility['HRRR'] ?

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault4"
                                    checked={this.state.selectedSource == PARTITIONS.HRRR}
                                    value={PARTITIONS.HRRR}
                                />

                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    <li className="layer-tooltip">

                                        <p className={"measurement-text"}>
                                            {this.state.i18n.HRRR_LABEL}
                                        </p>

                                        <span className="layer-tooltiptext">
                                            {this.state.i18n.HRRR_TOOLTIP}
                                        </span>

                                    </li>
                                </label>

                            </div>
                            :
                            <div/>
                    }



                </div>

            </div>
        )

    }

    _renderCollapsedSidePanel () {

        return (
            <div>
                <div id="SidePanel" className="side-panel-minified">
                    <a  type='button' onClick={this._onToggleExpandedState.bind(this)} className="side-panel-button-collapsed">
                        <MdKeyboardArrowRight size={42}/>
                    </a>
                    {this._renderLocationSearchEntry()}
                    { this.state.geocodeResponse != "" ? this._renderLocationSearchPanel(): ""}

                </div>

            </div>

        )

    }

    _renderLocationSearchEntry (){

        let geoResponse = "blank";
        return (
            <div className={"location-search"}>
                <input
                    type="text"
                    name="name"
                    className={"location-search-box"}
                    placeholder={this.state.i18n.LOCATION_SEARCH_DEFAULT}
                    value={this.state.rawLocationText}
                    autocomplete="off"
                    onChange={event => {

                        this.setState({rawLocationText:event.target.value});

                        //example props "country=US&language=ES&"
                        geoResponse =  ApiService.Geocode.forwardGeocode(event.target.value,"country=US&");

                        geoResponse.then( response => {
                            if(!response || (response && response.error) ){
                                //clear form
                                this.setState({geocodeResponse:""});
                            }
                            else{
                                console.log("Forward Geocode Succeeded");
                                this.setState({geocodeResponse:response});
                            }
                        });
                    }
                    }
                    onClick = { () => { this.setState({rawLocationText:""})}}
                >
                </input>
            </div>
        )
    }

    _buildPartitionsFromSelectors(value, airNowSelected, purpleAirSelected, claritySelected, monitorForecastSelected){
        let partitions = [];

        if(claritySelected){
            partitions.push(PARTITIONS.CLARITY);
        }
        if(monitorForecastSelected){
            partitions.push(PARTITIONS.MONITOR_FORECAST);
        }
        if(airNowSelected){
            partitions.push(PARTITIONS.AIRNOW);
        }
        if(purpleAirSelected){
            partitions.push(PARTITIONS.PURPLEAIR);
        }


        //if source value
        if( value == PARTITIONS.FORECAST || value == PARTITIONS.KRIGING || value == PARTITIONS.HRRR | value == PARTITIONS.NAQFC){
            partitions.push(Number(value));
        }

        return partitions
    }

    onLocationTrigger = (feature) => {
        this.setState({rawLocationText: feature.place_name, geocodeResponse:""})
        this.props.parentCenterCallback(feature.center);
    }

    onSelectorTrigger = (value, isAirNow, isPurpleAir, isClarity, isMonitorForecast) => {
        this.props.parentSelectorCallback(this._buildPartitionsFromSelectors(value,isAirNow,isPurpleAir,isClarity,isMonitorForecast))
    }

    _renderLocationSearchPanel (){

        let responseFeautures =  this.state.geocodeResponse != "" ? this.state.geocodeResponse.features : [];

        return(

            <div className={"search-dropdown"}>
                {
                    responseFeautures.map( (element) => {
                        return (
                            <div className={"location-select-button"} onClick={() => {this.onLocationTrigger(element); this.props.parentZoomCallback(9);}}>
                                {element.place_name}
                            </div>
                        )})
                }
            </div>
        )

    }

    _renderAttribution(){

        //TODO - Move to config/i18n
        const attributionToTitle = {
            [PARTITIONS.FORECAST]: "Forecast",
            [PARTITIONS.KRIGING]: "Kriging",
            [PARTITIONS.MONITOR_FORECAST]:"",
            [PARTITIONS.HRRR]:  '<a href="https://rapidrefresh.noaa.gov/" target="_blank">NCEP RAP</a>',
            [PARTITIONS.NAQFC]: '<a href="https://vlab.noaa.gov/web/osti-modeling/air-quality" target="_blank">NWS</a>',
            [PARTITIONS.AIRNOW]:  '<a href="https://www.airnow.gov/" target="_blank">AirNow</a>',
            [PARTITIONS.CLARITY]: '<a href="https://www.clarity.io/" target="_blank">Clarity</a>',
            [PARTITIONS.PURPLEAIR]:'<a href="https://www2.purpleair.com/" target="_blank">PurpleAir</a>'
        }

        let partitions = this._buildPartitionsFromSelectors(
            this.state.selectedSource,
            this.state.airNowSelected,
            this.state.purpleAirSelected,
            this.state.claritySelected,
            this.state.monitorForecastSelected
        );

        let attributions = [];

        partitions.forEach((val,i) =>{

            let spacer = '';

            if(i != partitions.length - 1) {
                spacer = '<a>, </a>';
            }

            if( val == PARTITIONS.KRIGING){
                //spacer = '<a>, </a>';
                attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.AIRNOW] + '<a>, </a>' +'</a>'));
                attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.PURPLEAIR] + '<a>, </a>' +'</a>'));
                if(!this.state.envIsProd) {
                    attributions.push(parse("<a>" + attributionToTitle[PARTITIONS.CLARITY] + '<a>, </a>' + '</a>'));
                }
                attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.HRRR] + '<a>, </a>' +'</a>'));
                attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.NAQFC] +'</a>'));

                /* NEEDED FOR PROD IF CLARITY IS OPTIONAL
                if(this.state.partitions.includes(PARTITIONS.CLARITY)){
                  attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.NAQFC] + spacer +'</a>'));
                }
                else{
                  attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.NAQFC] +'</a>'));
                }
                */

            }
            else if(val == PARTITIONS.FORECAST){
                
                attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.NAQFC] + spacer +'</a>'));
            }
            else if(partitions.includes(PARTITIONS.KRIGING) && (val == PARTITIONS.AIRNOW || val == PARTITIONS.PURPLEAIR || val == PARTITIONS.CLARITY || PARTITIONS.MONITOR_FORECAST)){
                //skip
                
            }
            else if(partitions.includes(PARTITIONS.FORECAST) && ( val == PARTITIONS.NAQFC)){
                
            }
            else if( val == PARTITIONS.MONITOR_FORECAST && ! partitions.includes(PARTITIONS.KRIGING)){
                if(! partitions.includes(PARTITIONS.AIRNOW)){
                    attributions.push( parse("<a>" + attributionToTitle[PARTITIONS.AIRNOW] + '<a>, </a>' +'</a>'));
                }
                if(! partitions.includes(PARTITIONS.PURPLEAIR)) {
                    attributions.push(parse("<a>" + attributionToTitle[PARTITIONS.PURPLEAIR] + '<a>, </a>' + '</a>'));
                }
            }
            else{
                attributions.push( parse("<a>" + attributionToTitle[val] + spacer +'</a>'));
            }

        })

        return(
            < div className={"bottom-container"}>
                <div className="selector-label">
                    {this.state.i18n.ATTRIBUTION_LABEL}
                </div>
                <div className={"attribution-container"}>
                    {attributions}
                </div>
            </div>

        )
    }

    _renderExapndedSidePanel () {


        let sidePanelExpandedClass = "expanded";

        return (
            <div >

                <div id="SidePanel" className="side-panel-expanded">


                        {this._renderSelectors()}
                        {this._renderAttribution()}

                </div>
            </div>

        )
    }

    render(){
        if(this.state.isExpanded){
            return this._renderExapndedSidePanel();
        }
        else{
            return this._renderCollapsedSidePanel();
        }
    }
}
export default SidePanel;