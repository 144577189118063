import { Circle as CircleStyle, RegularShape as RegularShapeStyle, Fill, Stroke, Style } from "ol/style";

// ol/style/Circle - https://openlayers.org/en/latest/apidoc/module-ol_style_Circle-CircleStyle.html
// ol/style/Fill - https://openlayers.org/en/latest/apidoc/module-ol_style_Fill-Fill.html
// ol/style/Stroke - https://openlayers.org/en/latest/apidoc/module-ol_style_Stroke-Stroke.html
// ol/style/Style - https://openlayers.org/en/latest/apidoc/module-ol_style_Style-Style.html

const VALUE_IDX = 0;
const COLOR_IDX = 1;
const EXACTAQ_PM25_LINEAR = [
  [-10.0, [0,120,191, 1.0]],
  [0.0, [0,120,191, 1.0]],
  [2.0, [44,164,221, 1.0]],
  [4.0, [73,201,245, 1.0]],
  [6.0, [69,184,85, 1.0]],
  [8.0, [46,136,65, 1.0]],
  [9.1, [225,205,23, 1.0]],
  [20.0, [225,201,23, 1.0]],
  [27.0, [225,198,23, 1.0]],
  [35.5, [243,134,9, 1.0]],
  [39.0, [243,126,9, 1.0]],
  [45.0, [246,119,8, 1.0]],
  [51.0, [255,111,0, 1.0]],
  [55.5, [255,81,0, 1.0]],
  [72.0, [255,51,0, 1.0]],
  [90.0, [255,34,0, 1.0]],
  [107.0, [255,21,0, 1.0]],
  [125.5, [123,13,219, 1.0]],
  [150, [114,14,196, 1.0]],
  [175.0, [98,13,168, 1.0]],
  [200.0, [87,14,152, 1.0]],
  [225.5, [145,44,23, 1.0]],
  [9999, [145,44,23, 1.0]]
];
const US_EPA_AQI_LINEAR = [
  // [value, [r, g, b, a]]
  [-10.0,  [0, 228, 0, 1.0]],
  [0.0,    [0, 228, 0, 1.0]],
  [12.1,   [255, 255, 0, 1.0]],
  [35.5,   [255, 126, 0, 1.0]],
  [55.5,   [255, 0, 0, 1.0]],
  [150.5,  [143, 63, 151, 1.0]],
  [250.5,  [2, 0, 35, 1.0]],
  [9999.0, [2, 0, 35, 1.0]]
];

function getShapeStyle(shape, color) {
  let shapeStyle;
  if (shape === "square") {
    shapeStyle = new RegularShapeStyle({
      points: 4,
      radius: 7,
      angle: Math.PI / 4,
    });
  } else if (shape === "triangle") {
    shapeStyle = new RegularShapeStyle({
      points: 3,
      radius: 7,
      angle: 0,
    });
  } else if (shape === "star") {
    shapeStyle = new RegularShapeStyle({
      points: 5,
      radius: 7,
      radius2: 3,
      angle: 0,
    });
  } else { // default to "circle"
    shapeStyle = new CircleStyle({
      radius: 5
    });
  }
  shapeStyle.setFill(new Fill({"color": color}));
  shapeStyle.setStroke(new Stroke({
    color: "white",
    width: 1
  }));
  return new Style({image: shapeStyle});
};

function getMidColor(value, startValue, startColor, endValue, endColor) {
  let midColor = [0, 0, 0, 0.0];  // No Data, Hidden
  const delta1 = value - startValue;
  const delta2 = endValue - startValue;
  const pct = delta1 / delta2;
  for (let i in startColor) {
    const c1 = startColor[i];
    const c2 = endColor[i];
    midColor[i] = c1 + ((c2 - c1) * pct);
  }
  return midColor;
}

function buildScaleValueStyle(value, scale, shape) {
  let color = [0, 0, 0, 0.0];  // No Data, Hidden
  for (let i = 0; i < scale.length; i++) {
    const prev_stop = scale[i-1];
    const curr_stop = scale[i];
    if (value < curr_stop[VALUE_IDX]) {
      if (i !== 0) {
        color = getMidColor(value,
            prev_stop[VALUE_IDX], prev_stop[COLOR_IDX],
            curr_stop[VALUE_IDX], curr_stop[COLOR_IDX]);
      }
      break;
    }
  }

  return getShapeStyle(shape, color);
}

function buildScaleValueMixedShapeStyle(value, product, scale) {
  
  let color = [0, 0, 0, 0.0];  // No Data, Hidden
  let shape = "";
  if(product == "airnow"){
    shape = "circle"
  }
  else if (product == "purpleair"){
    shape = "square"
  }
  for (let i = 0; i < scale.length; i++) {
    const prev_stop = scale[i-1];
    const curr_stop = scale[i];
    if (value < curr_stop[VALUE_IDX]) {
      if (i !== 0) {
        color = getMidColor(value,
            prev_stop[VALUE_IDX], prev_stop[COLOR_IDX],
            curr_stop[VALUE_IDX], curr_stop[COLOR_IDX]);
      }
      break;
    }

  }
  return getShapeStyle(shape, color);
}

export default {
  BuildConcentrationCircleStyle: function(value) {
    return buildScaleValueStyle(value, EXACTAQ_PM25_LINEAR, "circle");
  },
  BuildConcentrationSquareStyle: function(value) {
    return buildScaleValueStyle(value, EXACTAQ_PM25_LINEAR, "square");
  },
  BuildConcentrationTriangleStyle: function(value) {
    return buildScaleValueStyle(value, EXACTAQ_PM25_LINEAR, "triangle");
  },
  BuildConcentrationMixedStyle: function(value, product){
    return buildScaleValueMixedShapeStyle(value, product,  EXACTAQ_PM25_LINEAR);
  },
  PointSelectedFn: function(feature) {
    return getShapeStyle("star", "magenta");
  },
};
