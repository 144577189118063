import React, { useState} from "react";
import { fromLonLat, get } from "ol/proj";
import { Style, Icon } from "ol/style";
import "ol/ol.css";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import Point from "ol/geom/Point";
import {PARTITIONS, DOMAINS, POLLUTANTS, buildS3Path} from "../S3PathBuilder"

import { Layers, TileLayer, VectorLayer, WebGLTileLayer } from "./Layers";
import { xyz, vector, geotiff } from "./Source";
import { Controls, FullScreenControl, ScaleLineControl, ZoomControl, ZoomSliderControl, AttributionControl } from "./Controls";
import { Interactions, FeatureSelectInteraction } from "./Interactions";
import { Overlays, PopupOverlay } from "./Overlay";


import FeatureStyles from "./Features/Styles";
import Map from "./Map";
import RasterStyles from "./Rasters/Styles";
import appConfig from "../config/AppConfig";
import moment from "moment";

import AppNavbar from "../components/AppNavbar";
import SidePanel from "../components/SidePanel";
import TimeSelector from "../components/TimeSelector";
import  "./MapPage.css"
import {ApiService} from "../ApiService";
import * as $ from "jquery";
import {CookieManager} from "../util/CookieManager";


function addMarkers(lonLatArray) {

  var iconStyle = new Style({
    image: new Icon({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      src: "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-47-32.png",
    }),
  });
  let features = lonLatArray.map((item) => {
    let feature = new Feature({
      geometry: new Point(fromLonLat(item)),
    });
    feature.setStyle(iconStyle);
    return feature;
  });
  return features;
}

class MapPage extends React.Component {

  constructor (props) {
    super(props);

    const centerLngLat = [-122.3477,37.9358];
   // const centerLngLat = [-100.555183,39.809860];
    const mapBoxDarkURL = "https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}";
    this.baseLayerURL = mapBoxDarkURL + "?access_token=" + appConfig.MAPBOX_ACCESS_TOKEN;

    this.state = {
      center: centerLngLat,
      dateTime:  moment.utc().startOf('hour').add(-1,'hours'),
      partitions: [],
      defaultPartitions:[],
      zoom: 8,
      useOtherRaster: false,
      rasterSource: null,
      airNowSource: null,
      purpleAirSource: null,
      claritySource: null,
      monitorForecastSource: null,
      xyzSource: null,

      showLayer1: true,
      showMarker: true,
      centerChangeDue:false,

      pointIsActive:false,
      pointInfo:{
        concentrationValue:null,
        concentrationColor:[0,0,0,0],
        place:"",
        coordinates:[0,0],
        vectorClickType:"",
        requestData: null
      },

      userSettings: null,

      currentForecastInitSelected: true

    };

  }

  componentDidMount(){
    this.setState(
      {
        rasterSource : (geotiff({sources: [{url: buildS3Path(this._getSourcePartition(this.state.partitions),this.state.dateTime)}]})),
        airNowSource: this._formatVectorSource(PARTITIONS.AIRNOW, this.state.dateTime),
        claritySource: this._formatVectorSource(PARTITIONS.CLARITY, this.state.dateTime),
        purpleAirSource: this._formatVectorSource(PARTITIONS.PURPLEAIR, this.state.dateTime),
        monitorForecastSource: this._formatVectorSource(PARTITIONS.MONITOR_FORECAST, this.state.dateTime, this.state.currentForecastInitSelected),
      });

    this.setState({xyzSource: (xyz({url: this.mapBoxDarkURL + "?access_token=" + appConfig.MAPBOX_ACCESS_TOKEN, wrapX: true}))});

    let mainContext = this;
    let userSettingRequest= {"id":CookieManager.get("user_id")}
    $.when(
        ApiService.Livemap.getUserSettings(userSettingRequest)
    ).done( function(settingsResult){

      let default_partitions = settingsResult.response.webapp_settings.layers.default_selected.map( (default_partition) =>PARTITIONS[default_partition])
      mainContext.setState({userSettings:settingsResult.response, defaultPartitions:default_partitions})
    });

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( this.state.userSettings != prevState.userSettings){

      let initalDefaultLayers = []
      this.state.userSettings.webapp_settings.layers.default_selected.forEach( (layer) => {
        initalDefaultLayers.push(PARTITIONS[layer])
      })
      this.setState({ partitions:initalDefaultLayers})
    }

  }


  _isVectorPartition(partition){
    return(
        partition == PARTITIONS.CLARITY ||
        partition == PARTITIONS.PURPLEAIR ||
        partition == PARTITIONS.AIRNOW ||
        partition == PARTITIONS.MONITOR_FORECAST
    )
  }

  _getSourcePartition(partitions){
    let sourcePartition = 1;
    partitions.forEach(element => {
      if(! this._isVectorPartition(element)){
        sourcePartition = element;
      }
    })
    return sourcePartition;
  }

  _formatRasterSource(partition,time){
    return geotiff({sources: [{url: buildS3Path(partition, time)}]});
  }

  _formatVectorSource(partition,time, currInitForecast){
    return vector({
      url: buildS3Path(partition, time, currInitForecast),
      format: new GeoJSON({dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})
    });
  }

  _handleCenterCallback (newCenter) {
    this.setState({centerChangeDue: true});
    this.setState({center:newCenter});
  }

  _handleZoomCallback (newZoom){
    this.setState({zoom:newZoom});
  }

  _handleSelectorCallback (newPartitions){
    this.setState({
      partitions:newPartitions,

      //if source has changed update, else leave untouched
      rasterSource: this._getSourcePartition(newPartitions) === this._getSourcePartition(this.state.partitions) ?
          this.state.rasterSource : this._formatRasterSource(this._getSourcePartition(newPartitions),this.state.dateTime),
      airNowSource: (PARTITIONS.AIRNOW in newPartitions) !=  (PARTITIONS.AIRNOW in this.state.partitions) ?
          this._formatVectorSource(PARTITIONS.AIRNOW,this.state.dateTime) : this.state.airNowSource,
      purpleAirSource: (PARTITIONS.PURPLEAIR in newPartitions) !=  (PARTITIONS.PURPLEAIR in this.state.partitions) ?
          this._formatVectorSource(PARTITIONS.PURPLEAIR,this.state.dateTime) : this.state.purpleAirSource,
      claritySource: (PARTITIONS.CLARITY in newPartitions) !=  (PARTITIONS.CLARITY in this.state.partitions) ?
          this._formatVectorSource(PARTITIONS.CLARITY,this.state.dateTime) : this.state.claritySource,
      monitorForecastSource: (PARTITIONS.MONITOR_FORECAST in newPartitions) !=  (PARTITIONS.MONITOR_FORECAST in this.state.partitions) ?
          this._formatVectorSource(PARTITIONS.MONITOR_FORECAST,this.state.dateTime, this.state.currentForecastInitSelected) : this.state.monitorForecastSource,

      pointIsActive: false,
    });
  }

  _handleTimeSelectorCallback(newTime){

    if(newTime != this.state.dateTime){
      this.setState({
        dateTime:newTime,
        rasterSource: this._formatRasterSource(this._getSourcePartition(this.state.partitions),newTime),
        airNowSource: this._formatVectorSource(PARTITIONS.AIRNOW,newTime),
        purpleAirSource: this._formatVectorSource(PARTITIONS.PURPLEAIR,newTime),
        claritySource: this._formatVectorSource(PARTITIONS.CLARITY,newTime),
        monitorForecastSource: this._formatVectorSource(PARTITIONS.MONITOR_FORECAST,newTime, this.state.currentForecastInitSelected),
        pointIsActive: false,
      });
    }

  }

  _handleForecastInitCallback(currentForecastInitSelected){
    console.log("init callback hit")
    this.setState({
      currentForecastInitSelected:currentForecastInitSelected,
      monitorForecastSource:  this._formatVectorSource(PARTITIONS.MONITOR_FORECAST,this.state.dateTime, currentForecastInitSelected) ,
      pointIsActive: false,
    })
  }

  _handleCenterChangeDue(dueBool){
    this.setState({centerChangeDue: dueBool});
  }

  _handlePointInfoCallback(concVal, concColor, place, latlong,vectorType, requestData=null) {

    if(requestData){
      let dateTime = moment(this.state.dateTime);
      let dt_now = moment.utc().add(-1,'hours').set({minute:0,second:0,millisecond:0})



      if(this.state.currentForecastInitSelected){
        if(dateTime > dt_now){
          requestData["target_timestamp"]=moment(dt_now).unix()
        }
        else{
          requestData["target_timestamp"]=moment(dateTime).unix()
        }
      }
      else{
        requestData["target_timestamp"]=moment(dt_now).startOf('day').unix()
      }

    }

    this.setState({
      pointInfo:
          {
            concentrationValue: concVal,
            concentrationColor:  concColor,
            place: place,
            coordinates: latlong,
            vectorClickType:vectorType,
            requestData:requestData,
          },
    });

    this.setState({pointIsActive:true});
  }

  _handlePointActiveCallback(isActive){
    this.setState({pointIsActive:isActive});
  }


  _renderSingleVectorLayer(partition){

    let featureValueStyleFn;
    let source;

    if(partition == PARTITIONS.AIRNOW){
      featureValueStyleFn = FeatureStyles.BuildConcentrationCircleStyle;
      source = this.state.airNowSource;
    }
    else if (partition == PARTITIONS.PURPLEAIR){
      featureValueStyleFn= FeatureStyles.BuildConcentrationSquareStyle;
      source = this.state.purpleAirSource;
    }
    else if  (partition == PARTITIONS.CLARITY){
      featureValueStyleFn= FeatureStyles.BuildConcentrationTriangleStyle;
      source = this.state.claritySource;
    }
    else {
      featureValueStyleFn= FeatureStyles.BuildConcentrationMixedStyle;
      source = this.state.monitorForecastSource;
    }

    return(
        <VectorLayer
            source={source}
            renderOrder={function(a, b) {return b.getProperties().concentration - a.getProperties().concentration}}
            featureValueStyleFn={featureValueStyleFn}
            zIndex={2}
        />
    )
  }

  _renderSourceLayer() {
    return(
        <WebGLTileLayer
            name={"raster-data"}
            source={this.state.rasterSource}
            att
            style={RasterStyles.EXACTAQ_PM25_LINEAR}
            zIndex={1}
        />
    )
  }

  _renderVectorLayers() {
    return(
        <div>
          {this.state.partitions.includes(PARTITIONS.AIRNOW)?this._renderSingleVectorLayer(PARTITIONS.AIRNOW):null}
          {this.state.partitions.includes(PARTITIONS.PURPLEAIR)?this._renderSingleVectorLayer(PARTITIONS.PURPLEAIR):null}
          {this.state.partitions.includes(PARTITIONS.CLARITY)?this._renderSingleVectorLayer(PARTITIONS.CLARITY):null}
          {this.state.partitions.includes(PARTITIONS.MONITOR_FORECAST)?this._renderSingleVectorLayer(PARTITIONS.MONITOR_FORECAST):null}
        </div>
    )
  }

  render(){
    return(
        <div>

          <AppNavbar/>
          <SidePanel
              pointInfo={this.state.pointInfo}
              pointIsActive={this.state.pointIsActive}
              defaultSelected={this.state.defaultPartitions}
              parentCenterCallback={this._handleCenterCallback.bind(this)}
              parentSelectorCallback={this._handleSelectorCallback.bind(this)}
              parentZoomCallback={this._handleZoomCallback.bind(this)}
              webappSettings={this.state.userSettings? this.state.userSettings.webapp_settings: null }
          />
          <TimeSelector
              parentTimeCallback = {this._handleTimeSelectorCallback.bind(this)}
              parentInitCallback = {this._handleForecastInitCallback.bind(this)}
              monitorForecastSelected={this.state.partitions.includes(PARTITIONS.MONITOR_FORECAST)}
          />

          <Map
              parentPointCallback={this._handlePointInfoCallback.bind(this)}
              parentActiveCallback={this._handlePointActiveCallback.bind(this)}
              tgt_timestamp={moment(this.state.dateTime).unix()}
              partitions={this.state.partitions}
              center={fromLonLat(this.state.center)}
              defaultCenter={"defaultCenter"}
              centerChangeDue={this.state.centerChangeDue}
              toggleCenterDue = {this._handleCenterChangeDue.bind(this)}
              zoom={this.state.zoom}
              minZoom={"minZoom"}
              minZoom={"maxZoom"}
              view={this.state.userSettings ? this.state.userSettings.ol_settings.view : null}
          >

            <Layers>
              <TileLayer source={xyz({url: this.baseLayerURL})} zIndex={0} />

              {this._renderVectorLayers()}
              {this._renderSourceLayer()}

            </Layers>

            <Controls>
              <FullScreenControl />
              <AttributionControl />
              <ScaleLineControl units="metric" />
              <ZoomControl />
              <ZoomSliderControl />
            </Controls>

            <Overlays>

              <PopupOverlay
                  sources={[this.state.rasterSource,this.state.monitorForecastSource]}
                  dt={this.state.dateTime}
                  partitions={this.state.partitions}
                  pointIsActive={this.state.pointIsActive}
                  pointInfo={this.state.pointInfo}
                  parentActiveCallback={this._handlePointActiveCallback.bind(this)}
                  targetLayerName={"raster-data"}>
              </PopupOverlay>

            </Overlays>

            <Interactions>
              <FeatureSelectInteraction style={FeatureStyles.PointSelectedFn} />
            </Interactions>
          </Map>
        </div>
    );
  }
}

export default MapPage;
