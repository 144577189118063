import React from "react"
import "./Login.css";
import Spinner from "./components/Spinner";
import * as i18nDict  from "./i18n/i18n";
import {getLocale} from "./components/i18n";
import {useState} from "react";
import {render} from "react-dom";
import {ApiService} from "./ApiService"
import AppNavbar from "./components/AppNavbar";
import AppConfig from "./config/AppConfig.js";




export class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // session state
            authToken: false,

            // ui state
            i18n: i18nDict[getLocale()],
            locale: getLocale(),
            isMobile: false,


            //login state
            isAuthenticating: false,
            isDisabled: false,
            errorMessage:"",
            username:"",
            password:"",

        }
    }



    handleSubmit (event){
        event.preventDefault();

        this.setState({isAuthenticating:true, isDisabled: true, errorMessage:""})
        let authUserResponse = ApiService.Auth.loginAsUser({"Username":this.state.username,"Password":this.state.password})

        authUserResponse.then( response => {

                if(!response || (response && response.error) ){
                    //clear form
                    this.setState({username:"",password:""});
                    this.setState({errorMessage:"Incorrect username or password"});
                    this.setState({isAuthenticating:false, isDisabled: false})
                }
                else{
                    console.log("Login attempt succeeded");
                    this.setState({errorMessage:""});
                    window.location.assign(AppConfig.MAP_PAGE_ROUTE);
                }
            });
    }


    render() {

        return (
            <div>
                <AppNavbar/>
                <div className="Auth-form-container">
                    <form className="Auth-form" onSubmit={event => this.handleSubmit(event)}>
                        <div className="Auth-form-content">
                            <h3 className="Auth-form-title">Sign In</h3>
                            <div className="form-group mt-3">
                                <div className={"invalidFeedback"}> {this.state.errorMessage} </div>
                                <label>{this.state.i18n.USERNAME_LABEL}</label>
                                <input
                                    type="text"
                                    name='username'
                                    disabled={this.state.isDisabled}
                                    className="form-control mt-1"
                                    placeholder={this.state.i18n.USERNAME_PROMPT}
                                    onChange={event => this.setState({username:event.target.value})}
                                    value={this.state.username}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>{this.state.i18n.PASSWORD_LABEL}</label>
                                <input
                                    type="password"
                                    name="password"
                                    disabled={this.state.isDisabled}
                                    className="form-control mt-1"
                                    placeholder={this.state.i18n.PASSWORD_PROMPT}
                                    onChange={event => this.setState({password:event.target.value})}
                                    value={this.state.password}
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="btn btn-primary">
                                    {this.state.isAuthenticating ? <Spinner/>: this.state.i18n.SUBMIT_LABEL}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }


}

export default Login;