const AppConfig = {
    COGNITO_USER_POOL_ID:process.env.REACT_APP_COGNITO_USER_POOL_ID,
    COGNITO_CLIENT_ID:process.env.REACT_APP_COGNITO_CLIENT_ID,
    COGNITO_REGION:process.env.REACT_APP_COGNITO_REGION,
    MAP_PAGE_ROUTE: "#/map" ,
    LOGIN_PAGE_ROUTE: "#/login",
    auth : {
        refreshIntervalMillis : 5 * 60000
    },

    MAPBOX_ACCESS_TOKEN:process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    MAPBOX_BASE_URL:process.env.REACT_APP_MAPBOX_BASE_URL,
    
    LIVEMAP_BASE_URL:process.env.REACT_APP_LIVEMAP_BASE_URL,

    SHARED_S3_PREFIX:process.env.REACT_APP_SHARED_S3_PREFIX,

    HRRR_BASE_KEY: process.env.REACT_APP_HRRR_BASE_KEY || "mapping/normalize/noaa/hrrr/%s/%s/%s/%s",
    HRRR_BASE_NAME:  process.env.REACT_APP_HRRR_BASE_NAME || "noaa_hrrr_%s_pm2p5_%s.tiff",

    NAQFC_BASE_KEY: process.env.REACT_APP_NAQFC_BASE_KEY || "mapping/normalize/noaa/naqfc-bc/%s/%s/%s/%s",
    NAQFC_BASE_NAME: process.env.REACT_APP_NAQFC_BASE_NAME || "noaa_naqfc-bc_conus_%s_%s.tiff",

    Kriging_BASE_KEY: process.env.REACT_APP_Kriging_BASE_KEY || "mapping/curated/kriging/model/%s/%s/%s/%s",
    Kriging_BASE_NAME: process.env.REACT_APP_Kriging_BASE_NAME || "kriging_model_conus_pm2p5_%s.tiff",

    Forecast_BASE_KEY: process.env.REACT_APP_Forecast_BASE_KEY || "mapping/curated/forecast/concentration/%s/%s/%s/%s",
    Forecast_BASE_NAME: process.env.REACT_APP_Forecast_BASE_NAME || "forecast_concentration_contig-us_pm2p5_%s.tiff",

    AirNow_BASE_KEY: process.env.REACT_APP_AirNow_BASE_KEY || "mapping/normalize/airnow/obs/%s/%s/%s/%s",
    AirNow_BASE_NAME: process.env.REACT_APP_AirNow_BASE_NAME || "airnow_obs_conus_pm2p5_%s.geojson",

    Clarity_BASE_KEY: process.env.REACT_APP_Clarity_BASE_KEY || "mapping/normalize/clarity/obs/%s/%s/%s/%s",
    Clarity_BASE_NAME: process.env.REACT_APP_Clarity_BASE_NAME || "clarity_obs_conus_pm2p5_%s.geojson",

    PurpleAir_BASE_KEY: process.env.REACT_APP_PurpleAir_BASE_KEY || "mapping/normalize/purpleair/obs1hr/%s/%s/%s/%s",
    PurpleAir_BASE_NAME: process.env.REACT_APP_PurpleAir_BASE_NAME || "purpleair_obs1hr_conus_pm2p5_%s.geojson",

    MonitorForecast_BASE_KEY: process.env.REACT_APP_MonitorForecast_BASE_KEY || "mapping/curated/forecast/obs/%s/%s/%s/%s/%s",
    MonitorForecast_BASE_NAME: process.env.REACT_APP_MonitorForecast_BASE_NAME || "forecast_obs_contig-us_pm2p5_%s_%sh.geojson"
};

export default AppConfig