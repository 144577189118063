import React, { useContext, useEffect, useState } from "react";
import { ZoomSlider } from "ol/control";
import MapContext from "../Map/MapContext";

// ol/control/ZoomSlider - https://openlayers.org/en/latest/apidoc/module-ol_control_FullScreen-FullScreen.html

const ZoomSliderControl = () => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers ZoomSlider Control Object
        let zoomSliderControl = new ZoomSlider({});

        // Add ZoomSlider Control to map
        map.controls.push(zoomSliderControl);

        return () => map.controls.remove(zoomSliderControl);
    }, [map]);

    return null;
};

export default ZoomSliderControl;