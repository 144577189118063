import React, {CSSProperties, PropsWithoutRef} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import "@fortawesome/fontawesome-svg-core/styles.css"


const Spinner: React.FC = ({className, style, ...rest}) => {
    let spinnerStyle = style || {color: 'black', margin:'auto auto', width:"100%", height:"32px"}

    return (<FontAwesomeIcon icon={faSpinner} className={'fa-spin '+className} style={spinnerStyle} {...rest} />)
}

export default Spinner;