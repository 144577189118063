import config from "./config/AppConfig";

let request = require('request');



const __geocodeQuery = function (dataset, query, props, done) {

    let url = config.MAPBOX_BASE_URL + dataset + '/' + query + ".json?" + props + 'access_token=' + config.MAPBOX_ACCESS_TOKEN;

    request(url , function (err, response, body) {
        if (err || response.statusCode !== 200) {
            return done(err || JSON.parse(body));
        }

        done(null, JSON.parse(body));
    });
};

const getForwardGeocode = async (locationString, props) => {

    return new Promise((resolve, reject) => __geocodeQuery('mapbox.places',locationString, props, (err, geoData) => {
        if(err) {
            reject(err)
        } else {
            resolve(geoData)
        }
    }))
}

const getReverseGeocode = async (longitude, latitude, props) => {
    return new Promise((resolve, reject) => __geocodeQuery('mapbox.places',(longitude + "," + latitude), props, (err, geoData) => {
        if(err) {
            reject(err)
        } else {
            resolve(geoData)
        }
    }))
}

export{getForwardGeocode,getReverseGeocode}