import React, { useRef, useState, useEffect } from "react"
import * as ol from "ol";
import * as olProj from 'ol/proj';
import "./Map.css";
import MapContext from "./MapContext";
import {ApiService} from "../../ApiService";
import { fromLonLat, get } from "ol/proj";


const Map = (props) => {
    const mapRef = useRef();
    const [map, setMap] = useState(null);

    const [locationSearchOccured, setLocationSearchOccured] = useState(false);

    let children = props.children;
    let zoom = props.zoom;
    let center = props.center;
    let view = props.view
    let centerChangeDue = props.centerChangeDue;
    let toggleCenterDue = props.toggleCenterDue;
    let tgt_timestamp = props.tgt_timestamp

    let parentPointCallback = props.parentPointCallback;

    
    const getMidColor = (value) => {
        let startValue;
        let startColor;
        let endValue;
        let endColor;

        if( value < 0){
            return "rgba(0, 120, 191, 1.0)";
        }
        else if(value < 2){
            startValue=0;
            startColor=[0, 120, 191, 1.0];
            endValue=2;
            endColor=[44, 164, 221, 1.0];
        }
        else if(value < 4){
            startValue=2;
            startColor=[44, 164, 221, 1.0];
            endValue=4;
            endColor=[73, 201, 245, 1.0];
        }
        else if (value < 6){
            startValue= 4;
            startColor=[73, 201, 245, 1.0];
            endValue= 6
            endColor=[69, 184, 85, 1.0]
        }
        else if (value < 8){
            startValue=6;
            startColor=[69, 184, 85, 1.0];
            endValue=8;
            endColor=[46, 136, 65, 1.0];
        }
        else if(value < 9.1){
            startValue=8;
            startColor=[46, 136, 65, 1.0];
            endValue=9.1;
            endColor=[225, 205, 23, 1.0];
        }
        else if (value < 20){
            startValue=9.1;
            startColor=[225, 205, 23, 1.0];
            endValue=20;
            endColor=[225, 201, 23, 1];
        }
        else if(value < 27){
            startValue=20;
            startColor=[225, 201, 23, 1];
            endValue=27;
            endColor=[225, 198, 23, 1];
        }
        else if (value < 35.5){
            startValue=27;
            startColor=[225, 198, 23, 1];
            endValue=35.5;
            endColor=[243,134,9,1];
        }
        else if(value < 39){
            startValue=35.5;
            startColor=[243,134,9,1];
            endValue=39;
            endColor=[243,126,9,1];
        }
        else if (value < 45.0){
            startValue=39;
            startColor=[243,126,9,1];
            endValue=45.0;
            endColor=[246,119,8,1];
        }
        else if(value < 51.0){
            startValue=45.0;
            startColor=[246,119,8,1];
            endValue=51.0;
            endColor=[255,111,0,1];
        }
        else if (value < 55.5){
            startValue=51.0;
            startColor=[255,111,0,1];
            endValue=55.5;
            endColor=[255,81,0,1];
        }
        else if(value < 72.0){
            startValue=55.5;
            startColor=[255,81,0,1];
            endValue=72.0;
            endColor=[255,51,0,1];
        }
        else if (value <90.0){
            startValue=72.0;
            startColor=[255,51,0,1];
            endValue=90.0;
            endColor=[255,34,0,1];
        }
        else if(value < 107.0){
            startValue=90.0;
            startColor=[255,34,0,1];
            endValue=107.0;
            endColor=[255,21,0,1];
        }
        else if (value < 125.5){
            startValue=107.0;
            startColor=[255,21,0,1];
            endValue=125.5;
            endColor=[123,13,219,1];
        }
        else if(value < 150.0){
            startValue=125.5;
            startColor=[123,13,219,1];
            endValue=150.0;
            endColor=[114,14,196,1];
        }
        else if (value < 175.0){
            startValue=150.0;
            startColor=[114,14,196,1];
            endValue=175.0;
            endColor=[98,13,168,1];
        }
        else if(value < 200.0){
            startValue=175.0;
            startColor=[98,13,168,1];
            endValue=200.0;
            endColor=[87,14,152,1];
        }
        else if (value < 225.5){
            startValue=200.0;
            startColor=[87,14,152,1];
            endValue=225.5;
            endColor=[145,44,23,1];
        }
        else{
            return "rgba(145,44,23,1)";
        }

        let midColor = [0, 0, 0, 0.0];  // No Data, Hidden
        const delta1 = value - startValue;
        const delta2 = endValue - startValue;
        const pct = delta1 / delta2;
        for (let i in startColor) {
            const c1 = startColor[i];
            const c2 = endColor[i];
            midColor[i] = c1 + ((c2 - c1) * pct);
        }
        return "rgba(" +midColor[0]+","+midColor[1]+","+midColor[2]+","+midColor[3]+")";
    }

    const getShift = (value) =>{
        return( Math.min(100 * value/225.5, 100))
    }

    // on component mount
    useEffect(() => {
        // Create initial map options
        let options = {
            view: new ol.View({center:center, zoom:zoom}),
            layers: [],
            controls: [],
            overlays: []
        };

        // Create OpenLayers Map Object
        let mapObject = new ol.Map(options);

        // Set base element map will load into
        mapObject.setTarget(mapRef.current);


        // Event for clicking on the map and showing a popup with data
        mapObject.on("singleclick", function(evt) {
            const pixel = evt.pixel;
            const overlay = this.getOverlayById("point-info-popup");
            // Get all features whose style shape intersects the clicked map point.
            const features = this.getFeaturesAtPixel(pixel);

            // Extract data for popup
            let value = false;
            let coordinate = false;
            let dataType = false;
            let vectorType;
            let sensor_id=undefined;
            let requestData = null;

            if (features.length) {
                dataType = "Vector";
                // If more than 1 feature, select whichever is in front
                const feature = features[0];

                if(feature.getStyle().image_.points_ == "Infinity"){
                    vectorType="airnow";
                }
                else if (feature.getStyle().image_.points_ == "4"){
                    vectorType="purpleair";
                }
                else if (feature.getStyle().image_.points_ == "3"){
                    vectorType="clarity";
                }

                coordinate = feature.getGeometry().getCoordinates(); // Set overlay to feature coordinate
                value = feature.getProperties().concentration;
                sensor_id = feature.getProperties().sensor_id;
            } else {
                dataType = "Raster";
                for (const layer of this.getAllLayers()) {
                    if (layer.get("name") === "raster-data") {
                        const data = layer.getData(evt.pixel); // [0] data, [1] opacity (is value 0 for "nodata")
                        if (data !== null && data[1] !== 0) {
                            
                            coordinate = evt.coordinate; // Set overlay to map-click coordinate
                            value = data[0];
                        }
                    }
                }
            }

            // Show and populate popup
            if (value !== false && coordinate !== false) {
                // Format data
                let lngLat = olProj.toLonLat(coordinate, "EPSG:3857");
                let x = lngLat[0]
                let y = lngLat[1]
                lngLat[0] = Math.trunc(lngLat[0] * 10000) / 10000;  // Truncate to 4 decimals
                lngLat[1] = Math.trunc(lngLat[1] * 10000) / 10000;  // Truncate to 4 decimals
                value = Math.trunc(value * 10) / 10;  // (for pm2.5) truncate to 1 decimal

                let geoResponse =  ApiService.Geocode.reverseGeocode(lngLat[0],lngLat[1], "types=place&");

                let location = "";
                geoResponse.then( response => {
                    if(!response || (response && response.error) ){

                    }
                    else{
                        console.log("Reverse Geocode Succeeded");
                        if(response.features.length === 0){
                            location = [""];
                        }
                        else{
                            location = response.features[0].place_name.split(',');
                        }


                        let geoContent = overlay.getElement().querySelector(".reverse-geocode-result");


                        if(location.length > 1){
                            geoContent.innerHTML = "<p>" +location[0] + "," + " " + location[1] +
                                "<br>" + lngLat[1] + " °N, " + lngLat[0] + " °W</br></p>";



                            if(dataType == "Vector"){
                                requestData = {
                                    sensor_id:sensor_id,
                                    source:vectorType,
                                    variable:"pm2p5",
                                    target_timestamp:tgt_timestamp,
                                    y:y,
                                    x:x,
                                    srid:4326,
                                    prior_hours:23,
                                    later_hours:48
                                }
                            }


                            parentPointCallback(
                                value,
                                getMidColor(value),
                                location[0] + "," + " " + location[1], lngLat[1] + " °N, " + lngLat[0] + " °W",
                                vectorType,
                                requestData
                            );
                        }
                        else{
                            geoContent.innerHTML = "<p>" +location[0]+
                                "<br>" + lngLat[1] + " °N, " + lngLat[0] + " °W</br></p>";
                            
                            parentPointCallback(
                                value,
                                getMidColor(value),
                                location[0],
                                lngLat[1] + " °N, " + lngLat[0] + " °W",
                                vectorType,
                                requestData
                            );
                        }


                    }
                });

                // Add data to pupup
                let concContent =  overlay.getElement().querySelector(".concentration-value");
                concContent.innerHTML = "<p>" + value  + "<br>µg/m<sup>3</sup></br>" + "<p>";

                let gradientBall = overlay.getElement().querySelector(".concentration-ball");
                gradientBall.style.backgroundColor =  getMidColor(value);
                gradientBall.style.marginLeft = "calc(" + getShift(value) + "% - 8px)";

                // "Show" popup by setting its position on the map
                overlay.setPosition(coordinate);
            } else {
                props.parentActiveCallback(false);
                overlay.setPosition(undefined); // Hide the popup
            }
        });



        setMap(mapObject);

        return () => mapObject.setTarget(undefined);
    }, []);


    useEffect(() => {
        if (!map) return;
        map.getView().setZoom(zoom);
    }, [zoom]);

    useEffect(() => {
        if (!map) return;

        view.center = fromLonLat(view.center)

        if(view.extent){
            view.extent = [...fromLonLat([view.extent[0],view.extent[1]]), ...fromLonLat([view.extent[2],view.extent[3]])]
            //view.extent = [...fromLonLat([-122.34496065368275,37.79063341976136]), ...fromLonLat([-122.2308924183692,37.83477432268383])]
        }

        map.setView(new ol.View(
            view
        ));



    }, [view]);


    useEffect(() => {
        if (!map || center === map.getView().getCenter()) return;
        map.getView().setCenter(center);
        toggleCenterDue(false);
        setLocationSearchOccured(true);


    }, [centerChangeDue]);

    return (
        <MapContext.Provider value={{ map }}>
            <div ref={mapRef} className="ol-map">
                {children}
            </div>
        </MapContext.Provider>
    )
}

export default Map;