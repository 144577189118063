import { useContext, useEffect } from "react";
import OLTileLayer from "ol/layer/Tile";
import MapContext from "../Map/MapContext";

// ol/layer/Tile - https://openlayers.org/en/latest/apidoc/module-ol_layer_Tile-TileLayer.html

const TileLayer = ({ source, zIndex = 0 }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers Tile Layer Object
        let tileLayer = new OLTileLayer({
            source: source,
            zIndex: zIndex,
            preload: true,
            interpolate: false
        });

        // Add Tile Layer Object to map
        map.addLayer(tileLayer);

        return () => {
            if (map) {
                map.removeLayer(tileLayer);
            }
        };
    }, [map]);

    return null;
};

export default TileLayer;
