import { Vector as VectorSource } from 'ol/source';

// ol/source/Vector - https://openlayers.org/en/latest/apidoc/module-ol_source_Vector-VectorSource.html

function vector({ features = undefined, url = undefined, format = undefined }) {
    if (features === undefined && (url === undefined || format === undefined)) {
        throw "Vector source parameters not set";
    }
    // Create and return OpenLayers Vector Source Object
    return new VectorSource({
        features: features,  // Alternative to "url"
        url: url,            // Alternative to "features"
        format: format, // Required if "url" is set
        wrapX: false,
    });
}

export default vector;
