import React, { useContext, useEffect, useState } from "react";
import MapContext from "../Map/MapContext";
import {Attribution, defaults as defaultControls} from 'ol/control.js';

const AttributionControl = () => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        const attribution = new Attribution({
            collapsible: false,
        });

        // Add FullScreen Control to map
        map.controls.push(attribution);

        return () => map.controls.remove(attribution);
    }, [map]);

    return null;
};

export default AttributionControl;