import AppConfig from './config/AppConfig.js'
import {
    CognitoUser,
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoRefreshToken,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js'


let cognitoUserPool;
let cognitoUser;

const getCognitoUserPool = () => {
    if(!cognitoUserPool) {
        cognitoUserPool = new CognitoUserPool({
            UserPoolId: AppConfig.COGNITO_USER_POOL_ID,
            ClientId: AppConfig.COGNITO_CLIENT_ID
        })
    }
    return cognitoUserPool
}
const getCognitoUser = (username) => {
    cognitoUser = new CognitoUser({
        Username: username,
        Pool: getCognitoUserPool()
    })

    return cognitoUser
}

const asyncAuthenticateUser = (cognitoUser, authenticationDetails, newPasswordRequired) => {
    return new Promise((resolve, reject) => cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: resolve,
        onFailure: reject,
        newPasswordRequired: () => { newPasswordRequired(); resolve({"msg": "NEW_PASSWORD_REQUIRED"}); }
    }))
}

const asyncRefreshUser = (cognitoUser, cognitoRefreshToken) => {
    return new Promise((resolve, reject) => cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
        if(err) {
            reject(err)
        } else {
            resolve(session)
        }
    }))
}

const getAuthenticationDetails = (authUserRequest) => {
    // https://www.npmjs.com/package/amazon-cognito-identity-js
    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html

    let authenticationDetails = new AuthenticationDetails({
        Username: authUserRequest.Username,
        Password: authUserRequest.Password
    })
    return authenticationDetails;
}

const signOutUser = async () =>{
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser !== null) {
        cognitoUser.signOut();
    }
    else{
        return {error: "failed to sign out."}
    }
}


const refreshAuth = async (username, refreshToken) => {

    const cognitoUser = getCognitoUser(username)

    const cognitoRefreshToken = new CognitoRefreshToken({
        RefreshToken: refreshToken
    })

    try {
        const refreshResult = await asyncRefreshUser(cognitoUser, cognitoRefreshToken);

        if (refreshResult.getIdToken()) {

            return {
                AuthenticateUserResponse: {
                    idTokenPayload: refreshResult.getIdToken().decodePayload(),
                    idToken: refreshResult.getIdToken().getJwtToken(),
                    accessToken: refreshResult.getAccessToken().getJwtToken(),
                    accessTokenExpiration: refreshResult.getAccessToken().getExpiration(),
                    refreshToken: refreshResult.getRefreshToken().getToken()
                }
            }
        } else {
            console.error("could not reauthenticate", refreshResult)

            return { error: "could not reauthenticate"}
        }

    } catch (err) {
        console.error("could not reauthenticate", err)

        return { error: "could not reauthenticate"}
    }
}

const authenticateUser = async (authUserRequest) => {

    let authenticationDetails = getAuthenticationDetails(authUserRequest);

    const cognitoUser = getCognitoUser(authUserRequest.Username);



    try {
        const resp = await asyncAuthenticateUser(cognitoUser, authenticationDetails, authUserRequest.newPasswordRequired)

        const authResult = cognitoUser.getSignInUserSession()

        console.log(authResult.getIdToken().payload["sub"])
        if(authResult && authResult.getIdToken()) {
            return {
                AuthenticateUserResponse: {
                    idTokenPayload: authResult.getIdToken().decodePayload(),
                    idToken: authResult.getIdToken().getJwtToken(),
                    accessToken: authResult.getAccessToken().getJwtToken(),
                    accessTokenExpiration: authResult.getAccessToken().getExpiration(),
                    refreshToken: authResult.getRefreshToken().getToken(),
                    api_key: authResult.getIdToken().payload["custom:API_Key"],
                    user_id: authResult.getIdToken().payload["sub"]
                }
            }
        } else {
            console.error("Unexpected authentication error: ", authResult)
            return { error: "Unexpected authentication error" }
        }
    }
    catch (err) {
        console.error("Unexpected authentication error: ", err)
        return { error: err.message }
    }
}



export  {
    refreshAuth,
    authenticateUser,
    getAuthenticationDetails,
    signOutUser
}