
const en_us = {

    /******************
     *  Login Page    *
     ******************/
    SIGN_IN: "Sign In",
    USERNAME_LABEL: "Username",
    USERNAME_PROMPT: "Enter Username",
    PASSWORD_LABEL: "Password",
    PASSWORD_PROMPT: "Enter Password",
    SUBMIT_LABEL: "Submit",

    /******************
     *   Nav bar      *
     ******************/
    MAP:"Map",
    ABOUT:"About",
    CONTACT:"Contact",
    LOGO_TEXT:"Air Quality Where You Are",

    /******************
     *   Side Panel   *
     ******************/
    CONCENTRATION_MIN_VALUE:"0",
    CONCENTRATION_MAX_VALUE:"225",
    UNITS_UGM:"µg/m",
    PM25_LABEL:"PM2.5",
    POLLUTANTS_LABEL:"Pollutants",
    SCALE_LABEL:"Scale",
    CONCENTRATIONS_LABEL:"Concentrations",
    CONCENTRATION_POPUP_TEXT:"Concentration PM2.5",
    MEASUREMENTS_LABEL:"Measurement Data Providers",
    AIRNOW_LABEL:"AirNow",
    PURPLEAIR_LABEL:"PurpleAir",
    CLARITY_LABEL:"Clarity",
    MODEL_SOURCES_LABEL:"Model Sources",
    EXACTAQ_SOURCES_LABEL:"ExactAQ Results",
    KRIGING_LABEL:"Current Conditions",
    MONITOR_FORECAST_LABEL:"Monitor Forecast",
    FORECAST_LABEL:"Gridded Forecast",
    NAQFC_LABEL:"NOAA NAQFC",
    HRRR_LABEL:"NOAA HRRR",
    LOCATION_SEARCH_DEFAULT:"City, Street, ZIP Code",
    ATTRIBUTION_LABEL:"Selected Data Contributed By",
    PURPLEAIR_TOOLTIP:"Observations for air quality sensors obtained from PurpleAir. ExactAQ performs quality control on PurpleAir data including applying a correction to provide more accurate information and to make sensor data comparable to data from AirNow monitors. Visit PurpleAir.com for more information.",
    AIRNOW_TOOLTIP:"Observations for air quality monitors obtained from U.S. EPA AirNow. Data from AirNow are considered preliminary, unvalidated, and subject to change by U.S. EPA. ExactAQ performs quality control on AirNow data for use in ExactAQ models. Visit AirNow.gov for more information.",
    KRIGING_TOOLTIP:"ExactAQ uses geostatistical fusion to combine data from AirNow and PurpleAir with modeling from NOAA to provide a complete map of current air quality conditions at community-scale. Visit ExactAQ.com for more information.",
    MONITOR_FORECAST_TOOLTIP:"ExactAQ uses machine learning models to combine recent air quality observations with NOAA forecasts and other data sources, to predict hourly concentrations at AirNow and PurpleAir locations. Visit ExactAQ.com for more information.",
    FORECAST_TOOLTIP:"ExactAQ uses historical observations to correct for seasonal biases in NOAA forecasts. Visit ExactAQ.com for more information.",
    CLARITY_TOOLTIP:"Observations for air quality sensors obtained from Clarity. Clarity calibrates raw measurements using a generalized calibration profile and/or regional collocation results. Visit Clarity.io for more information.",
    NAQFC_TOOLTIP:"Forecasts obtained from the NOAA National Air Quality Forecast Capability. NOAA provides an hourly forecast twice daily with bias-corrected surface predictions. Visit https://rapidrefresh.noaa.gov/ for more information.",
    HRRR_TOOLTIP:"Forecasts obtained from the NOAA High-Resolution Rapid Refresh (HRRR) model. NOAA provides an hourly forecast at 3km resolution, that updates hourly, with surface predictions including weather and smoke pollution. HRRR provides Visit https://rapidrefresh.noaa.gov/hrrr/ for more information.",
    /******************
     *   Attrib Page  *
     ******************/
    ATTRIBUTION_LINK_LABEL:"Data Source Providers",
    ATTRIBUTION_PA_BLURB:"Contributed by PurpleAir",

    /******************
     *  Time Selector *
     ******************/
    FORECAST_TOGGLE_TITLE: "Forecast Initialization Time",
    FORECAST_TOGGLE_MIDNIGHT: "Midnight",
    FORECAST_TOGGLE_CURRENT: "Current",
};

module.exports = en_us