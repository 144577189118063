import React from "react"
import "./Login.css";
import Spinner from "./components/Spinner";
import * as i18nDict  from "./i18n/i18n";
import {getLocale} from "./components/i18n";
import {useState} from "react";
import "./Attribution.css";
import AppNavbar from "./components/AppNavbar";
import {BiLinkExternal} from "react-icons/bi"




export class Attribution extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // session state
            authToken: false,

            // ui state
            i18n: i18nDict[getLocale()],
            locale: getLocale(),
            isMobile: false,

        }
    }




    render() {

        return (
            <div>
                <AppNavbar/>
                <div className={"attribution-container"}>
                    <div className={"attribution-header"}>
                        {this.state.i18n.ATTRIBUTION_LINK_LABEL}
                    </div>
                    <div className={"attribution-item"}>
                        <a href={"https://www2.purpleair.com/"}>{this.state.i18n.ATTRIBUTION_PA_BLURB}<BiLinkExternal/></a>
                    </div>
                </div>

            </div>
        )
    }


}

export default Attribution;