import * as olSource from "ol/source";

// ol/source/XYZ - https://openlayers.org/en/latest/apidoc/module-ol_source_XYZ-XYZ.html

function xyz({ url, wrapX }) {
    // Create and return OpenLayers XYZ Source Object
    return new olSource.XYZ({
        url: url,
        wrapX: wrapX,
        attributions:'<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap</a> <b><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></b>',
        tileSize: [512, 512] // (default: [256, 256]) Bigger tiles are slower to load, but fewer requests "should" be made to MapBox API which helps us stay in free tier
    });
}

export default xyz;
