import { useContext, useEffect } from "react";
import OLVectorLayer from "ol/layer/VectorImage";
import MapContext from "../Map/MapContext";
import FeatureStyles from "../Features/Styles"
// ol/layer/Vector - https://openlayers.org/en/latest/apidoc/module-ol_layer_Vector-VectorLayer.html

const VectorLayer = ({ source, featureValueStyleFn, renderOrder = undefined, zIndex = 0 }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers Vector Layer Object
        let vectorLayer = new OLVectorLayer({
            source: source,
            zIndex: zIndex,
            renderOrder: renderOrder,
        });

        vectorLayer.getSource().on("featuresloadend", function(event) {
            this.forEachFeature(function(feature) {
                if (feature.getStyle() === null) {
                    let value = feature.get("concentration");
                    let vector_product_type = feature.get("source")
                    let style = featureValueStyleFn(value, vector_product_type);
                    feature.setStyle(style);
                }
            });
        });

        // Add Vector Layer Object to map
        map.addLayer(vectorLayer);

        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
            }
        };
    }, [source]);

    return null;
};

export default VectorLayer;