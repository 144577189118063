import React from "react";

// Style
import "./AppNavbar.css";

import * as i18nDict from "../i18n/i18n";
import {getLocale} from "./i18n";

class AppNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navigationLinksCollapsed: true,
            i18n: i18nDict[getLocale()],
            locale: getLocale(),
        };
    }

    _renderNavigationLinks() {
        let pathname = window.location.pathname;

        return (
                <ul className="nav navbar-nav">

                    <li>
                        <a href="/map" className={(pathname === "/" || pathname.indexOf("map") > -1 ? "active" : "")} >
                            <div className="nav-link-text">{this.state.i18n.MAP.toLowerCase()}</div>
                        </a>
                    </li>

                    <li>
                        <a href="/about" className={(pathname.indexOf("about") > -1 ? "active" : "")} >
                            <div className="nav-link-text">{this.state.i18n.ABOUT.toLowerCase()}</div>
                        </a>
                    </li>


                    <li>
                        <a href="/contact" className={(pathname.indexOf("contact") > -1 ? "active" : "")}>
                            <div className="nav-link-text">{this.state.i18n.CONTACT.toLowerCase()}</div>
                        </a>
                    </li>

                </ul>

        );

    }

    _renderDesktopNavbar(){

        return (

            <nav id="NavBar" className="navbar navbar-default navbar-custom">

                <div id="AppLogo">

                    <div className="logo-link-wrapper">
                        <a href="https://exactaq.com" className="logo-link" >
                            <div className="nav-logo" />
                        </a>
                    </div>

                    <div className="logo-text">
                        {this.state.i18n.LOGO_TEXT}
                    </div>

                    <div className="link-wrapper">
                        {/*this._renderNavigationLinks()*/}
                    </div>

                </div>

            </nav>

        )
    }

    render () {
        return this._renderDesktopNavbar();
    }

}

export default AppNavbar;
