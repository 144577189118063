import React, { useRef, useState, useContext, useEffect } from "react";
import * as ol from "ol";
import MapContext from "../Map/MapContext";
import "./PopupOverlay.css"

import * as i18nDict  from "../../i18n/i18n";
import TimeSeries from "../../components/TimeSeries"
import {getLocale} from "../../components/i18n";

import {ApiService} from "../../ApiService"
import {PARTITIONS} from "../../S3PathBuilder"
import Spinner from "../../components/Spinner"
import * as $ from "jquery";
import moment from 'moment'


const PopupOverlay = (props) => {
    const { map } = useContext(MapContext);
    const popupRef = useRef();
    const popupContentRef = useRef();
    const popupCloserRef = useRef();

    const i18n = i18nDict[getLocale()];
    const locale = getLocale();

    const [obsData,setObsData] = useState([]);
    const [fcstData,setFcstData] = useState([]);
    const [startTimestamp, setStartTimestamp] = useState(moment.utc())
    const [partitions, setPartitions] = useState([])
    const [shouldRenderTimeseries, setShouldRenderTimeseries] = useState(true)

    useEffect(() => {
        if (!map) return;

        const container = popupRef.current;
        const closer = popupCloserRef.current;

        // Create OpenLayers Overlay Object
        let overlay = new ol.Overlay({
            element: container,
            id: "point-info-popup",
            autoPan: {
                animation: {
                    duration: 250  // milliseconds
                }
            }
        });

        if(!props.pointIsActive)
        {
            setShouldRenderTimeseries(false);
            overlay.setPosition(undefined);
            closer.blur();
            // Unselect the corresponding map feature
            for (let interaction of map.getInteractions().getArray()) {
                if (interaction.get("name") === "feature-select") {
                    interaction.getFeatures().clear();
                }
            }
        }

        // Dismiss overlay when "x" button clicked
        closer.onclick = function() {

            props.parentActiveCallback(false);
            overlay.setPosition(undefined);
            closer.blur();
            // Unselect the corresponding map feature
            for (let interaction of map.getInteractions().getArray()) {
                if (interaction.get("name") === "feature-select") {
                    interaction.getFeatures().clear();
                }
            }
            return false;
        };

        // Add overlay to map
        map.addOverlay(overlay);

        return () => {
            if (map) {
                map.removeOverlay(overlay);
            }
        };
    }, [map, props.sources[1], props.sources[0]]);

    useEffect( () =>{
        setObsData([]);
        setFcstData([]);

        if(props.partitions.includes(PARTITIONS.MONITOR_FORECAST) &&  props.pointInfo.requestData && props.pointInfo.requestData.source != "clarity"){
            _formatRequestData(props.pointInfo);
            setShouldRenderTimeseries(true);
        }
        else{
            setShouldRenderTimeseries(false);

        }

    }, [props.pointInfo, props.partitions])


    const _formatRequestData = (pointInfo) => {
        setObsData([]);
        setFcstData([]);
        $.when(
            ApiService.Livemap.getSensorDataAtTime(pointInfo.requestData)
        ).done( function(sensorResult){
            setObsData(sensorResult.response.obs_values);
            setFcstData(sensorResult.response.fcst_values);
            setStartTimestamp(moment.unix(sensorResult.response.start_timestamp).utc())
        });

    }

    const _renderSpinnerContainer = () =>{
        return(
            <div className={"spinner-container"}>
                <Spinner />
            </div>

        )
    }

    return (
        <div ref={popupRef} className="ol-popup">
            <a ref={popupCloserRef} href="#" className="ol-popup-closer"></a>
            <div className="popup-content">
                <div className={"title-row"}>
                    {i18n.CONCENTRATION_POPUP_TEXT}
                </div>

                <div className={"concentration-row"}>
                    <div className={"slider-start-text"}>
                        {i18n.CONCENTRATION_MIN_VALUE}
                    </div>

                    <div className={"concentration-slider"}>
                        <div className={"concentration-ball"}/>
                    </div>

                    <div className={"slider-end-text"}>
                        {i18n.CONCENTRATION_MAX_VALUE}
                    </div>
                </div>

                <div className={"bottom-row"}>

                    <div className={"concentration-value"}>
                    </div>

                    <div className={"reverse-geocode-result"}>
                    </div>

                </div>

                {obsData.length > 0? <TimeSeries obsData={obsData} fcstData={fcstData} startTimestamp={startTimestamp}/>: shouldRenderTimeseries? _renderSpinnerContainer():<div/>}

            </div>
        </div>
    );

};

export default PopupOverlay;