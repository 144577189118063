import React, { useContext, useEffect, useState } from "react";
import { ScaleLine } from "ol/control";
import MapContext from "../Map/MapContext";

// ol/control/ScaleLine - https://openlayers.org/en/v7.2.2/apidoc/module-ol_control_ScaleLine-ScaleLine.html

const ScaleLineControl = ({ units = "imperial" }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers ScaleLine Control Object
        let scaleLineControl = new ScaleLine({
            units: units
        });

        // Add ScaleLine Control to map
        map.controls.push(scaleLineControl);

        return () => map.controls.remove(scaleLineControl);
    }, [map]);

    return null;
};

export default ScaleLineControl;