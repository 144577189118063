import React from "react";

const getBrowserLanguagePref = () => {
    return (navigator.languages && navigator.languages[0])
        || navigator.language
}

const lang_prefix_to_locale = {
    "en": "en_us",
    "es": "es"
}

export const getLocale = () => {
    const ls_locale = window.localStorage.getItem("locale")
    if(ls_locale) {
        return ls_locale
    }

    const browser_language_pref = getBrowserLanguagePref();
    const lang_prefix = browser_language_pref.split('-')[0].toLowerCase();
    if(typeof lang_prefix_to_locale[lang_prefix] !== 'undefined') {
        return lang_prefix_to_locale[lang_prefix]
    }

    return "en_us";
}

export const setLocale = (locale) => {
    window.localStorage.setItem("locale", locale);
    window.location.reload()
}