import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { HashRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from "./Login"
import Attribution from "./Attribution"
import AppNavbar from "./components/AppNavbar"
import MapPage from './map/MapPage';
import SidePanel from "./components/SidePanel";
import PrivateRoute from "./util/PrivateRoute";
import "./assets/css/google-webfonts-inter.css";



function App() {
  return (
      <HashRouter>
        <Routes>

            <Route path={"/"} element={<Navigate to={"map"} />}/>
            
            <Route extact path="/map" element={<PrivateRoute/>}>
                <Route exact path="/map" element={<MapPage/>}/>
            </Route>
            
            <Route path="/login" element={<Login/>} />

            <Route path="/attribution" element={<Attribution/>} />
            
        </Routes>
      </HashRouter>
  )
}

export default App