import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLWebGLTileLayer from "ol/layer/WebGLTile";

// ol/layer/WebGLTile - https://openlayers.org/en/latest/apidoc/module-ol_layer_WebGLTile-WebGLTileLayer.html

let WebGLTileLayer = ({ name, source, style, opacity = 1.0, cacheSize = 10240, zIndex = 0 }) => {
    const { map } = useContext(MapContext);



    useEffect(() => {


        if (!map) return;

        // Create OpenLayers WebGLTile Layer Object
        let webGLTileLayer = new OLWebGLTileLayer({
            source: source,
            preload: 1,
            opacity: opacity,
            style: style,
            cacheSize: cacheSize,
            zIndex: zIndex,
        });
        webGLTileLayer.set("name", name);
        webGLTileLayer.setOpacity(.6);

        // Add WebGLTile Layer Object to map
        map.addLayer(webGLTileLayer);

        return () => {
            if (map) {
                map.removeLayer(webGLTileLayer);
            }
        };
    }, [source, map]);

    // opacity change handler
    useEffect(() => {
            if (!map) return;
            for (const layer of map.getAllLayers()) {
                    if (layer.get("name") === name) {
                            layer.setOpacity(opacity);
                        }
                }
        }, [opacity]);



    return null;
};

export default WebGLTileLayer;
