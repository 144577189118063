import React from "react";

// Style
import "./TimeSeries.css";

import * as i18nDict from "../i18n/i18n";
import {getLocale} from "./i18n";

import {Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, TimeScale} from 'chart.js';
import moment from 'moment'
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale
);


class TimeSeries extends React.Component {

    constructor(props) {
        super(props);

        this.tz = moment.tz.guess();

        this.state = {
            i18n: i18nDict[getLocale()],
            locale: getLocale(),
            fcstValues: [],
            obsValues: [],
            dt:this.props.startTimestamp,
            timeSeriesRenderReady: false,

        };

    }

    componentDidMount(){
        this.setState(
            {
                fcstValues : this.props.fcstData,
                obsValues: this.props.obsData,
                dt: this.props.startTimestamp
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props != prevProps){
            this.setState(
                {
                    fcstValues : this.props.fcstData,
                    obsValues: this.props.obsData,
                    dt: this.props.startTimestamp
                });
        }
    }

    _getMidColor(value, startValue, startColor, endValue, endColor) {
        let midColor = [0, 0, 0, 0.0];  // No Data, Hidden
        const delta1 = value - startValue;
        const delta2 = endValue - startValue;
        const pct = delta1 / delta2;
        for (let i in startColor) {
            const c1 = startColor[i];
            const c2 = endColor[i];
            midColor[i] = c1 + ((c2 - c1) * pct);
        }
        return midColor;
    }

    _formatColorToRGBA(color) {
        const r = color[0];
        const g = color[1];
        const b = color[2];
        const a = color[3];
        return 'rgba('+r+', '+g+', '+b+', '+a+')';
    }

    _getValueColor(value) {
        const VALUE_IDX = 0;
        const COLOR_IDX = 1;
        const EXACTAQ_PM25_LINEAR = [
            [-10.0, [0, 120, 191, 1.0]],
            [0.0, [0, 120, 191, 1.0]],
            [2.0, [44, 164, 221, 1.0]],
            [4.0, [73, 201, 245, 1.0]],
            [8.0, [69, 184, 85, 1.0]],
            [10.0, [46, 136, 65, 1.0]],
            [12.1, [225, 205, 23, 1.0]],
            [20.0, [229, 192, 25, 1.0]],
            [27.0, [236, 185, 16, 1.0]],
            [35.5, [244, 177, 8, 1.0]],
            [39.0, [246, 163, 8, 1.0]],
            [45.0, [228, 158, 6, 1.0]],
            [51.0, [255, 119, 0, 1.0]],
            [55.5, [255, 100, 0, 1.0]],
            [80.0, [255, 68, 0, 1.0]],
            [105.0, [255, 47, 0, 1.0]],
            [130.0, [255, 13, 0, 1.0]],
            [150.5, [123, 13, 219, 1.0]],
            [175.0, [114, 14, 196, 1.0]],
            [200.0, [98, 13, 168, 1.0]],
            [225.0, [87, 14, 152, 1.0]],
            [250.5, [145, 44, 23, 1.0]],
            [350.5, [133, 36, 15, 1.0]],
            [9999, [133, 36, 15, 1.0]]
        ];

        let color = [0, 0, 0, 0.0];  // No Data, Hidden
        const scale = EXACTAQ_PM25_LINEAR;
        for (let i = 0; i < scale.length; i++) {
            const prev_stop = scale[i-1];
            const curr_stop = scale[i];
            if (value < curr_stop[VALUE_IDX]) {
                if (i !== 0) {
                    color = this._getMidColor(
                        value,
                        prev_stop[VALUE_IDX], prev_stop[COLOR_IDX],
                        curr_stop[VALUE_IDX], curr_stop[COLOR_IDX]
                    );
                }
                return this._formatColorToRGBA(color);
            }
        }
        return this._formatColorToRGBA(color);
    }

    _renderTimeseries() {


        /**********************************************
         *  Generate historic and forecast chart data  *
         ***********************************************/

        let now = this.state.dt;  // time from time selector
        now = moment(this.state.dt)
        const chartHours = 72;

        let obsValues = this.state.obsValues.map( (v) => v > 0 ? v : "N/A");
        let labels = [];
        let colors = [];

        let fcstValues = this.state.fcstValues.map( (v) => v > 0 ? v : "N/A");
        // generate historic data
        fcstValues.forEach( (value, i) => {
            labels.push(now.clone().add(i, 'hours'));
            colors.push(this._getValueColor(value));
        });
        let progress = {}
        let chartSetup = {
            data: {
                labels: labels,
                datasets: [

                    {
                    type: 'line',
                    label: 'Observed PM2.5 (ug/m3)',
                    data: obsValues,
                    pointRadius: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(0, 0, 0, .75)"
                    },
                    {
                        type: 'bar',
                        label: 'Forecasted PM2.5 (ug/m3)',
                        data: fcstValues,
                        backgroundColor: colors,
                        borderColor: colors
                    }
                ]
            },
            options: {

                scales: {
                    y: {
                        beginAtZero: true,
                        suggestedMax: 20, // If values < 20, 20 will be the chart max
                        grid: {
                            display: false // Hide background horizonal grid lines
                        }
                    },
                    x: {
                        type: "time",
                        grid: {
                            color: "rgba(0, 0, 0, 0)",  // Hide background vertical grid lines
                            tickColor: "rgba(0, 0, 0, 0.1)",  // Show faint tick marks
                            offset: false,  // Ensure ticks aligned to center of bars
                        },
                        ticks: {
                            major: {
                                enabled: true,  // Allow major ticks for special customization
                            },
                            minRotation: 0,  // keep labels horizontal
                            maxRotation: 0,  // keep labels horizontal
                            font: labels.map(l => ({
                                weight: l.hour() == 0 ? 'bold' : 'normal' // Bold midnight of every day
                            })),
                            callback: function(value, index, ticks) {
                                // Print date for each major tick, which should align to midnight each day
                                if (ticks[index] !== undefined) {
                                    if (ticks[index].major===true) {
                                        // Each array element is printed on a new line for us
                                        return [
                                            moment(ticks[index].value).format('h a'),  // 12 am, 1st line
                                            moment(ticks[index].value).format('MMM DD')  // Oct 25, 2nd line
                                        ];
                                    }
                                }
                                return value;
                            }
                        },
                        time: {
                            displayFormats: {
                                hour: "h A"  // 12 am for minor ticks
                            },
                            tooltipFormat: "YYYY-MM-DD HH:00Z"  // Hover tooltip should show full date/time
                        }
                    }
                },
                plugins: {
                    // Allow tooltip to display even when not directly hovering over a x-axis bar
                    tooltip: {
                        mode: 'index',
                        position: 'nearest',
                        intersect: false,
                    }
                }
            }
        };

        return (
            <div className={"timeseries-container"}>
                <Chart
                    type={'bar'}
                    data={chartSetup.data}
                    options={chartSetup.options}
                    plugins={chartSetup.plugins}

                />
            </div>
        );
    }


    render() {
        if (this.state.fcstValues.length > 0){
            return this._renderTimeseries()
        }

    }

}

export default TimeSeries;
