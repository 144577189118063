import React, { useContext, useEffect, useState } from "react";
import { Zoom } from "ol/control";
import MapContext from "../Map/MapContext";

// ol/control/Zoom - https://openlayers.org/en/latest/apidoc/module-ol_control_Zoom-Zoom.html

const ZoomControl = () => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers Zoom Control Object
        let zoomControl = new Zoom({});

        // Add Zoom Control to map
        map.controls.push(zoomControl);

        return () => map.controls.remove(zoomControl);
    }, [map]);

    return null;
};

export default ZoomControl;