
import AppConfig from "./config/AppConfig";
import {refreshAuth, authenticateUser, signOutUser} from "./Authentication";
import {getForwardGeocode, getReverseGeocode} from "./Geocode"
import {CookieManager} from "./util/CookieManager";
//import { CognitoJwtVerifier } from "aws-jwt-verify";

import * as $ from "jquery"

var ApiService = {

    Auth : {

        refreshAuthTokenInterval: function(data){
            setInterval(function(){
                console.debug("Refreshing auth token");
                this.refreshAuthToken(data);
            }, AppConfig.auth.refreshIntervalMillis);
            return this.refreshAuthToken(data);
        },

        refreshAuthToken: async function (data){
            try {
                let AuthenticateUserResponse = await refreshAuth(data);
                CookieManager.set("api_token", AuthenticateUserResponse);
                return AuthenticateUserResponse;
            } catch (e) {
                console.log("Error re-authenticating user", e);
            }
        },
        
        checkValidToken: async function (){
            try {
                return false;
                /*
                const verifier = CognitoJwtVerifier.create({
                    userPoolId: AppConfig.userPoolId,
                    tokenUse: "access",
                    clientId: AppConfig.clientId,
                });

                let token = CookieManager.get("api_token")
                const payload = await verifier.verify(token.accessToken);
                console.log("Token is valid. Payload:", payload);
                return true

                 */
            } catch (e){
                return false
            }
        },

        loginAsUser: async function (data){
            try {
                let AuthenticateUserResponse = await authenticateUser(data);
                CookieManager.set("api_token", AuthenticateUserResponse.AuthenticateUserResponse.accessToken);
                CookieManager.set("api_key", AuthenticateUserResponse.AuthenticateUserResponse.api_key);
                CookieManager.set("user_id", AuthenticateUserResponse.AuthenticateUserResponse.user_id);
                return AuthenticateUserResponse;
            } catch (e) {
                console.log("Error logging in user", e);
            }
        },

        logoutUser : async function (data, callbackFnc, scope) {
            try {
                let UserSignOutResponse = await signOutUser(data);
                CookieManager.remove("api_token");
                return UserSignOutResponse;
            } catch (e) {
                console.log("Error logging in user", e);
            }
        }
    },
    Geocode : {

        forwardGeocode : async function (data, props) {
            try {
                let ForwardGeocodeResponse = await getForwardGeocode(data, props);
                return ForwardGeocodeResponse;
            }
            catch (e) {
                console.log("Error running forward geocode", e);
            }
        },

        reverseGeocode : async function (longitude, latitude, props) {
            try {
                let ReverseGeocodeResponse = await getReverseGeocode(longitude,latitude, props);
                return ReverseGeocodeResponse;
            }
            catch (e) {
                console.log("Error running reverse geocode", e);
            }
        }

    },
    Livemap : {
        getSensorDataAtTime: async function (data, callback) {
            var xhr = $.ajax({
                url: AppConfig.LIVEMAP_BASE_URL + "sensors/get_data_at_time",
                data:data,
                dataType: "json",
                callback:callback,
                type: "GET",
                async: true,
                error: function(data) {
                    console.error(data);
                }
            });

            return xhr;
        },
        getUserSettings : function (data, callback, scope){

            var xhr = $.ajax({
                url: AppConfig.LIVEMAP_BASE_URL + "users/get_settings",
                data:data,
                dataType: "json",
                callback:callback,
                type: "GET",
                async: true,
                error: function(data) {
                    console.error(data);
                }
            });

            return xhr;

        }
    }
}
export {ApiService}

