const COOKIE_EXPIRE_TIME = {
    END_OF_SESSION : 0,
    SECOND : 1000,
    MINUTE : 1000 * 60,
    HOUR : 1000 * 60 * 60,
    DAY : 1000 * 60 * 60 * 24,
    YEAR : 1000 * 60 * 60 * 24 * 365,
    NEVER : 1000 * 60 * 60 * 24 * 365 * 20
};
const _DEFAULT_EXPIRE_TIME = COOKIE_EXPIRE_TIME.MINUTE * 30;
const _DEFAULT_PATH = "/";

var CookieManager = {
    set: function(key, value, expireTime, path) {
        var cookie = key + "=" + value;

        // add expire time
        expireTime = typeof expireTime !== "undefined" ? expireTime : _DEFAULT_EXPIRE_TIME;
        if (expireTime) {
            var date = new Date();
            date.setTime(date.getTime() + expireTime);
            cookie += "; expires=" + date.toUTCString();
        }

        // add path
        path = typeof path !== "undefined" ? path : _DEFAULT_PATH;
        if (path) {
            cookie += "; path=" + path;
        }

        // set cookie
        document.cookie = cookie;
    },
    get: function(key) {
        var cookieArray = document.cookie.split(';');
        for (var i = 0; i < cookieArray.length; i++) {
            var cookie = cookieArray[i].trim();
            var cookieKeyValue = cookie.split("=");
            if (cookieKeyValue[0] === key) {
                // Reset cookie expire time
                this.set(cookieKeyValue[0], cookieKeyValue[1]);
                return cookieKeyValue[1];
            }
        }
        return null;
    },
    remove: function(key) {
        document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
};

module.exports = {CookieManager};