import React, { useContext, useEffect } from "react";
import Select from 'ol/interaction/Select.js';
import MapContext from "../Map/MapContext";

// ol/interaction/Select - https://openlayers.org/en/v7.2.2/apidoc/module-ol_interaction_Select-Select.html

const FeatureSelectInteraction = ({ style, condition = undefined }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        // Create OpenLayers Select Object
        let select = new Select({
            condition: condition, // By default undefined uses singleClick
            style: style
        });
        select.set("name", "feature-select");

        // Add select interaction to map
        map.addInteraction(select);

        return () => {
            if (map) {
                map.removeInteraction(select);
            }
        };
    }, [map]);

    return null;
};

export default FeatureSelectInteraction;
